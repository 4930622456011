import React from 'react';

import './ProgressSkills.scss';

import SKILLS_DATA from '../../data/skills.json';

function ProgressSkills({ s, level }) {
  if (!s || !level) {
    return null;
  }

  const skill = SKILLS_DATA[s];

  if (!skill) {
    return (
      <div className="u-empty">
        No skill with ID "<strong>{s}</strong>"
      </div>
    );
  }

  const skillItems = Array.from('x'.repeat(skill.max));

  return (
    <div className="skill-progress--wrapper">
      {skillItems.map((s, i) => (
        <div
          key={i}
          className={`skill-progress--bar ${i < level ? 'is-active' : ''}`}
        />
      ))}
    </div>
  );
}

export default ProgressSkills;
