import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

import './WorldDetails.scss';

import WORLDS_DATA from '../data/worlds.json';
import LOTS_DATA from '../data/lots.json';
import HOUSEHOLDS_DATA from '../data/households.json';

import LotList from 'sections/families/LotList';
import { isResidentalLot } from 'shared/utils/worlds';

function WorldDetails() {
  let { id } = useParams();

  const world = WORLDS_DATA[id];
  if (!world) {
    return (
      <main className="world--wrapper">
        <div className="content world--title">
          <h1>No world with ID {id}</h1>
        </div>
      </main>
    );
  }

  return (
    <main className="world--wrapper">
      <img src={`/images/worlds/${id}.jpg`} alt={`Map over ${world.name}`} />
      <div className="content world--title">
        <h1>{world.name}</h1>
      </div>
      <div className="content is-wide">
        <ol className="world--lots">
          {world.lots?.map((l, i) => {
            const lot = l.lot && LOTS_DATA[l.lot];
            const lotType = l.type || lot?.type;
            let typeText = l.type || lot?.type;
            const owner = lot?.owner ? HOUSEHOLDS_DATA[lot.owner] : null;

            let text = (
              <em>
                {l.name}
                {l.notes && <small> ({l.notes})</small>}
              </em>
            );

            if (l.lot) {
              if (isResidentalLot(lot.type)) {
                text = getResidentialTextOldWay(id, i + 1);
                typeText = lot.name;
              } else {
                text = (
                  <span>
                    {lot.name}
                    <small>{owner ? ` (${owner.name})` : lot.notes}</small>
                  </span>
                );
              }
            } else {
              typeText = l.name;
            }

            if (lotType === 'Residential' || lotType === 'Apartments') {
              text = getResidentialTextOldWay(id, i + 1);
            }

            return (
              <li key={l + i} style={{ opacity: lot ? 1 : 0.5 }}>
                <img
                  src={`/images/icons/venues/${lotType}.png`}
                  className="world--venue"
                  alt={lotType}
                  title={typeText}
                />{' '}
                {text}
              </li>
            );
          })}
        </ol>
      </div>

      {world.lots.map(l => (l.lot ? <LotList key={l.lot} lotId={l.lot} /> : JSON.stringify(l.lot)))}
    </main>
  );
}

export default WorldDetails;

const getResidentialTextOldWay = (worldId, lotIdx) => {
  let text = '___';
  const world = WORLDS_DATA[worldId];

  const family = Object.values(HOUSEHOLDS_DATA)
    .filter(h => h.home.world === worldId && h.home.lot === lotIdx)
    .sort((a, b) => (a.home.flat > b.home.flat ? 1 : -1));

  const flats = world.lots[lotIdx - 1].flats || [];

  if (family.length === 1 && flats.length === 0) {
    text = family.map(f => (
      <Link to={`/family/${f._id}/`} key={f._id}>
        <span>{f.name} </span>
      </Link>
    ));
  } else if ((family.length > 1 && flats.length === 0) || family.length > flats.length) {
    text = 'Too many households! - ' + family.map(f => f.name).join(' / ');
  } else if (family.length === flats.length && flats.length > 0) {
    // text = family.map(f => f.name).join(' / ');
    text = family.map(f => (
      <Link to={`/family/${f._id}/`} key={f._id}>
        <span>{f.name} </span>
      </Link>
    ));
  } else if (family.length < flats.length) {
    let template = flats.map(f => <span>_____ </span>);

    family.forEach((f, i) => {
      template[i] = (
        <Link to={`/family/${f._id}/`} key={f._id}>
          <span>{f.name} </span>
        </Link>
      );
    });

    text = template;
  }

  return text;
};
