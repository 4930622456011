import React from 'react';

import './Lots.scss';

import LOTS_DATA from '../data/lots.json';
import WORLDS_DATA from '../data/worlds.json';
import { isResidentalLot } from 'shared/utils/worlds';

function Lots() {
  const worldsList = Object.values(WORLDS_DATA).sort((a, b) =>
    a.name > b.name ? 1 : -1,
  );
  const lotsList = Object.values(LOTS_DATA).sort((a, b) =>
    a.name > b.name ? 1 : -1,
  );
  const lotTypes = {};

  lotsList.forEach(l => {
    const existing = lotTypes[l.type];

    const lotWorlds = [
      l._id.substring(0, 1),
      l._id.substring(0, 2),
      l._id.substring(0, 3),
    ];

    lotWorlds.forEach(i => {
      // More specific overrides.
      // Eg. First finds W for Windenburg, then WC for Willow Creek
      if (WORLDS_DATA[i]) {
        l.world = { name: WORLDS_DATA[i].name };
      }
    });

    if (existing) {
      lotTypes[l.type].push(l);
      return;
    }

    lotTypes[l.type] = [l];
  });

  worldsList.forEach(w => {
    w.lots.forEach(l => {
      if (!l.type) {
        return;
      }

      if (
        l.type === 'Ancient Ruins' ||
        l.type === 'Chalet Gardens' ||
        l.type === 'Island Bluff'
      ) {
        return;
      }

      if (l.type === 'Center Park' || l.type === 'National Park') {
        l.notes = l.notes ? l.notes + ', ' + l.type : l.type;
        l.type = 'Park';
      }

      const existing = lotTypes[l.type];
      l.world = { name: w.name };

      if (existing) {
        lotTypes[l.type].push(l);
        return;
      }

      lotTypes[l.type] = [l];
    });
  });

  // const lotTypesKeys = Object.keys(lotTypes).sort();
  const lotTypesKeys = Object.keys(lotTypes)
    .sort()
    .sort((a, b) => (lotTypes[a].length >= lotTypes[b].length ? 1 : -1));

  return (
    <main className="lots--wrapper">
      <div className="content worlds--title">
        <h1>Lots by type</h1>
      </div>
      <div className="content worlds--content">
        {lotTypesKeys.map(k => {
          const lotType = lotTypes[k];

          if (isResidentalLot(k)) {
            return null;
          }

          return (
            <div>
              <h2>
                {k} ({lotType.length})
              </h2>
              {lotType.map(l => (
                <div key={l._id} className="lots--lot">
                  {l.name}
                  <div className="lots--lot-info">
                    {l.world?.name}
                    {l.owner ? <span> -- {l.owner}</span> : ''}
                  </div>
                  <div className="lots--lot-info">
                    {l.notes ? <span> ({l.notes})</span> : ''}
                  </div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </main>
  );
}

export default Lots;
