import React from 'react';

import './Favourites.scss';

import FavSim from 'sections/favs/FavSim';

function Favourites() {
  return (
    <main className="favourites--wrapper">
      <div className="content favourites--title">
        <h1>My favourite sims</h1>
      </div>

      <FavSim simId="AliHu" />
      <FavSim simId="AxeCa" />
      <FavSim simId="MarSt" />
      <FavSim simId="MiaCa" />
      <FavSim simId="VerHa" />
    </main>
  );
}

export default Favourites;
