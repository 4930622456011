import React from 'react';
import './Global.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Header from './Header';

import Home from 'pages/Home';
import Mods from 'pages/Mods';

import Favourites from 'pages/Favourites';
import Careers from 'pages/Careers';
import Aspirations from 'pages/Aspirations';
import Ages from 'pages/Ages';
import Clubs from 'pages/Clubs';

import SimDetails from 'pages/SimDetails';

import AllSims from 'pages/AllSims';
import ListsList from 'pages/ListsList';

import Worlds from 'pages/Worlds';
import WorldDetails from 'pages/WorldDetails';
import Lots from 'pages/Lots';

import Families from 'pages/Families';
import FamilyDetails from 'pages/FamilyDetails';

import Setup from 'pages/Setup';

function Global() {
  return (
    <Router>
      <Header />
      <Route
        path="*"
        render={_ => {
          if (typeof window.scroll === 'function') {
            window.scroll(0, 0);
          }

          return null;
        }}
      />
      <Switch>
        <Route path="/world/:id" component={WorldDetails} />
        <Route path="/world" component={Worlds} />

        <Route path="/family/:id" component={FamilyDetails} />
        <Route path="/family" component={Families} />

        <Route path="/list/favs" component={Favourites} />
        <Route path="/list/clubs" component={Clubs} />
        <Route path="/list/careers" component={Careers} />
        <Route path="/list/aspirations" component={Aspirations} />
        <Route path="/list/age" component={Ages} />
        <Route path="/list/lots" component={Lots} />

        <Route path="/list" component={ListsList} />

        <Route path="/all/:id" component={SimDetails} />
        <Route path="/all" component={AllSims} />

        <Route path="/mods" component={Mods} />

        <Route path="/setup" component={Setup} />

        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default Global;
