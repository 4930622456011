import React from 'react';

import './SetupHousehold.scss';

import SIMS_DATA from '../../data/sims.json';
import LOTS_DATA from '../../data/lots.json';
import WORLDS_DATA from '../../data/worlds.json';

import SetupSim from './SetupSim';
import { formatNumber } from 'shared/utils/numbers';

function SetupHousehold(props) {
  const household = props.household;
  const members = household.members.map(m => SIMS_DATA[m] || m).sort((a, b) => (a.firstname > b.firstname ? 1 : -1));
  const world = WORLDS_DATA[household.home?.world];
  const lotId = household.home ? `${household.home.world}${household.home.lot}${household.home.flat || ''}` : '';
  const lot = LOTS_DATA[lotId] || { name: household.home?.lot ? world.lots[household.home?.lot - 1]?.name : '' };
  const owner = Object.values(LOTS_DATA).filter(l => l.owner === household._id);

  return (
    <div className="setup--household">
      <div className="setup--household-title">
        <h3 className="setup--household-name">
          {household.name} {household.temp}
        </h3>
        <div>
          {members.map(m => (
            <img key={m._id} src={`/images/sims/${m._id}.jpg`} alt={m.firstname} width="50px" />
          ))}
        </div>
      </div>
      <div>
        {lot?.name} - {world?.name} || § {formatNumber(household.funds) || '?'}
        {household.vault && ` (§ ${formatNumber(household.vault)})`}
      </div>
      {owner.length > 0 && <div>Owner of {owner.map(l => l.name)}</div>}
      <p>{household.description || '-'}</p>
      <div>{members.map(s => (typeof s === 'string' ? `No sim with ID ${s}` : <SetupSim key={s._id} sim={s} />))}</div>
    </div>
  );
}

export default SetupHousehold;
