import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import './FamilyDetails.scss';

import SIMS_DATA from '../data/sims.json';
import HOUSEHOLDS_DATA from '../data/households.json';
import WORLDS_DATA from '../data/worlds.json';
import LOTS_DATA from '../data/lots.json';

import ProfileTop from 'sections/profile/ProfileTop';

import { getHouseholdWeeklyPay } from '../shared/utils/calculations';
import { formatWeeklyMoney, formatNumber } from '../shared/utils/numbers';

function FamilyDetails() {
  let { id } = useParams();

  const fullHousehold = HOUSEHOLDS_DATA[id] || {};
  const lotId = fullHousehold.home.world + fullHousehold.home.lot;
  const fullLot = LOTS_DATA[lotId];

  if (!fullHousehold) {
    return (
      <main className="family--wrapper">
        <div className="content">
          <h1>No family with ID {id}</h1>
        </div>
      </main>
    );
  }

  const world = WORLDS_DATA[fullHousehold.home.world];
  const membersWeeklyPay = getHouseholdWeeklyPay(fullHousehold);
  const lotWeeklyCost = (fullLot?.lotTaxes || 0) + (fullLot?.rent || 0);

  return (
    <main className="sim--wrapper">
      <div className="content favourites--title">
        <h1>{fullHousehold.name}</h1>
        <div>{fullHousehold.description}</div>
      </div>

      <div className="content">
        {world ? (
          <>
            <Link to={`/world/${world._id}`} className="sim--world">
              <img
                src="/images/icons/world.png"
                alt="Home world"
                className="sim--world-icon"
              />
              <div>
                <div className="sim--world-name">
                  {WORLDS_DATA[fullHousehold.home?.world]?.name}
                </div>
                <div className="sim--world-lot">
                  {fullHousehold.home?.townie ? 'Townie' : fullLot?.name}
                </div>
              </div>
            </Link>
            <div className="sim--world-description">{fullLot?.description}</div>
          </>
        ) : (
          <div className="content is-empty u-empty">
            No world with ID "<strong>{fullHousehold.home?.world}</strong>"
          </div>
        )}
      </div>

      <div className="content">
        <div className="--wrapper">
          <p>
            Funds after buying lot:
            {fullLot ? (
              <strong> § {formatNumber(fullHousehold.funds)}</strong>
            ) : (
              <em> Unknown</em>
            )}
          </p>
          <p>
            Lot value:
            {fullLot ? (
              <>
                <strong>
                  {' '}
                  § {formatNumber(fullLot.house + fullLot.value)}
                </strong>{' '}
                (§ {formatNumber(fullLot.house)})
              </>
            ) : (
              <em> Unknown</em>
            )}
          </p>
          <p>
            Weekly costs:
            {fullLot ? (
              <strong> {formatWeeklyMoney(lotWeeklyCost)}</strong>
            ) : (
              <em> Unknown</em>
            )}
          </p>
          <p>
            Weekly income:
            {membersWeeklyPay > 0 ? (
              <strong> {formatWeeklyMoney(membersWeeklyPay)}</strong>
            ) : (
              <em> Unknown</em>
            )}
          </p>
        </div>
      </div>

      {fullHousehold.members.sort().map(s => (
        <ProfileTop key={s} sim={SIMS_DATA[s]} shortVersion={true} />
      ))}
    </main>
  );
}

export default FamilyDetails;
