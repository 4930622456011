import React from 'react';

import './Setup.scss';

import HOUSEHOLDS_DATA from '../data/households.json';
import SIMS_DATA from '../data/sims.json';
import CLUBS_DATA from '../data/clubs.json';

import SetupHousehold from 'sections/setup/SetupHousehold';

function Setup() {
  const clubs = Object.values(CLUBS_DATA);
  const clubLeaders = clubs.map(c => c.leader);
  const clubMembers = clubs.map(c => c.members).flat();

  const basedOnClubs = household => {
    const hasClub = household.members.filter(s => clubMembers.includes(s));
    const hasLeader = household.members.filter(s => clubLeaders.includes(s));

    // No member has any club connections
    if (hasClub.length === 0) {
      return 0;
    }

    // Only leaders and no other members
    if (hasClub.length === hasLeader.length) {
      return 100 + hasLeader.length;
    }

    // Both leaders and other members
    if (hasClub.length > hasLeader.length && hasLeader.length > 0) {
      return 50 - hasClub.length;
    }

    return hasClub.length;
  };

  const familyFame = household => {
    return household.members.reduce((acc, mem) => acc + (SIMS_DATA[mem]?.celebrity || 0), 0);
  };

  const households = Object.values(HOUSEHOLDS_DATA)
    .sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
    .sort((a, b) => (familyFame(a) <= familyFame(b) ? 1 : -1))
    .sort((a, b) => (basedOnClubs(a) <= basedOnClubs(b) ? 1 : -1))
    .map(h => {
      h.temp = `${basedOnClubs(h)} - ${familyFame(h)}`;

      return h;
    });

  return (
    <main className="setup--wrapper">
      <div className="content setup--title">
        <h1>Setup from zero</h1>
      </div>

      <div className="content is-wide setup--content">
        {households.map(h => {
          return <SetupHousehold key={h._id} household={h} />;
        })}
      </div>
    </main>
  );
}

export default Setup;
