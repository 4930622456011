import React from 'react';
import { Link } from 'react-router-dom';

import './ListsList.scss';

function ListList() {
  return (
    <main className="lists--wrapper">
      <div className="content lists--title">
        <h1>Lists of sims</h1>
      </div>
      <div className="content lists--content">
        <ListItem link="/list/favs" name="My favourites" />
      </div>
      <div className="content lists--content">
        <ListItem link="/list/careers" name="Careers" />
        <ListItem link="/list/aspirations" name="Aspirations" />
        <ListItem link="/list/clubs" name="Clubs" />
      </div>
      <div className="content lists--content">
        <ListItem link="/family" name="Households" />
      </div>
      <div className="content lists--content">
        <ListItem link="/list/lots" name="Venues" />
        <ListItem link="/setup" name="Setup" />
      </div>
    </main>
  );
}

export default ListList;

const ListItem = ({ link, name }) => {
  return (
    <Link to={link} key={name} className={`lists--link ${name.split(' ').some(s => s.length > 10) ? 'use-small' : ''}`}>
      {name}
    </Link>
  );
};
