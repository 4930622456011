import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SIMS_DATA from '../../data/sims.json';
import AGES_DATA from '../../data/ages.json';
import CLUBS_DATA from '../../data/clubs.json';
import TRAITS_DATA from '../../data/traits.json';
import FILTERS_DATA from '../../data/filters.json';
import WORLDS_DATA from '../../data/worlds.json';
import HOUSEHOLDS_DATA from '../../data/households.json';
import LOTS_DATA from '../../data/lots.json';

import {
  filterSimsByOrigin,
  filterSimsByLifeState,
  filterSimsByAchievement,
  filterSimsByAge,
  filterSimsByGender,
  filterSimsBySexuality,
  filterSimsByTrait,
  filterSimsByWorld,
  parseFilterToString,
  parseFilterFromString,
  filterSimsByClub,
  filterSimsByRelationship,
  filterSimsByPotentialClub,
  filterSimsByExcludingClub,
} from '../../shared/utils/filters';

import './ListsSims.scss';

function ListsSims(props) {
  const filterNames = {
    origin: 'Origin',
    lifeState: 'Life state',
    age: 'Age',
    gender: 'Gender',
    sexuality: 'Sexuality',
    achievement: 'Achievement',
    relationship: 'Relationship',
    trait: 'Trait',
    world: 'World',
    club: 'Club',
  };
  const [activeFilters, setActiveFilters] = useState({});
  const [activeOptions, setActiveOptions] = useState({
    opacityOption: '3',
  });

  const showCelebrityLevel = activeFilters.achievement === '2';
  const showDegree = activeFilters.achievement === '1';
  const showGhost = activeFilters.lifeState === '4';
  const showClub = activeFilters.club && activeFilters.club !== '0' && activeFilters.club !== '2';
  const verifyClub =
    activeFilters.club && activeFilters.club !== '0' && activeFilters.club !== '1' && activeFilters.club !== '2';

  useEffect(() => {
    setActiveFilters(parseFilterFromString(props.queryParams));
  }, [props.queryParams]); // Can this be? React says warning, but I want it to run only ONCE on load.

  const filterAges = [{ id: 0, name: 'All' }].concat(
    Object.values(AGES_DATA).map(d => ({
      id: d._id,
      name: d.name,
    })),
  );

  const filterTraits = [{ id: 0, name: 'Any' }].concat(
    Object.values(TRAITS_DATA)
      .map(d => ({
        id: d._id,
        name: d.name,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1)),
  );

  const filterWorlds = [
    { id: 0, name: 'Any' },
    { id: 1, name: 'Townies' },
    { id: 2, name: 'Missing world' },
  ].concat(
    Object.values(WORLDS_DATA)
      .map(d => ({
        id: d._id,
        name: d.name,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1)),
  );

  const filterRelationship = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Single' },
    { id: 2, name: 'Dating' },
    { id: 3, name: 'Engaged' },
    { id: 4, name: 'Married' },
    // { id: 5, name: 'Ex-partner' },
    // { id: 6, name: 'Divorced' },
    //   { "id": 7, "name": "Cheating" }
  ];

  const filterClubs = [
    { id: 0, name: 'Any' },
    { id: 1, name: 'At least one' },
    { id: 2, name: 'None' },
  ].concat(
    Object.values(CLUBS_DATA)
      .map(d => ({
        id: d._id,
        name: d.name,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : -1)),
  );

  const optionOpacity = [
    { id: 0, name: 'Sim check' },
    { id: 2, name: 'House check' },
    { id: 3, name: 'Sim + house' },
    { id: 4, name: 'None' },
  ];

  const allSims = Object.values(SIMS_DATA);

  const simIds = allSims
    .filter(filterSimsByOrigin(activeFilters.origin))
    .filter(filterSimsByLifeState(activeFilters.lifeState))
    .filter(filterSimsByAchievement(activeFilters.achievement))
    .filter(filterSimsByAge(activeFilters.age))
    .filter(filterSimsByGender(activeFilters.gender))
    .filter(filterSimsBySexuality(activeFilters.sexuality))
    .filter(filterSimsByRelationship(activeFilters.relationship))
    .filter(filterSimsByTrait(activeFilters.trait))
    .filter(filterSimsByWorld(activeFilters.world))
    .filter(filterSimsByClub(activeFilters.club))
    .map(s => s._id);

  const potentialClubMembers = allSims
    .filter(filterSimsByPotentialClub(activeFilters.club))
    .filter(filterSimsByExcludingClub(activeFilters.club))
    .map(s => s._id);

  const onFilterChange = e => {
    const input = e.currentTarget;
    const value = input.value;
    const name = input.name;

    const newFilters = {
      ...activeFilters,
      [name]: value,
    };

    props.history.push({
      pathname: props.path,
      search: `?${parseFilterToString(newFilters)}`,
    });

    setActiveFilters(newFilters);
  };

  const onOptionChange = e => {
    const input = e.currentTarget;
    const value = input.value;
    const name = input.name;

    const newOptions = {
      ...activeOptions,
      [name]: value,
    };

    setActiveOptions(newOptions);
  };

  const getFilterMarkup = (key, options) => {
    return (
      <label className="sim-list--filter" key={key}>
        <div className="sim-list--filter-label">{filterNames[key]}</div>
        <select className="sim-list--filter-option" name={key} onChange={onFilterChange} value={activeFilters[key]}>
          {options.map(o => (
            <option value={o.id} key={o.id}>
              {o.name}
            </option>
          ))}
        </select>
      </label>
    );
  };

  return (
    <div className="sim-list--wrapper">
      <div className="sim-list--filters">
        {getFilterMarkup('age', filterAges)}
        {Object.keys(FILTERS_DATA).map(k => {
          const filter = FILTERS_DATA[k];

          return getFilterMarkup(k, filter);
        })}
        {getFilterMarkup('trait', filterTraits)}
        {getFilterMarkup('world', filterWorlds)}
        {getFilterMarkup('club', filterClubs)}
        {getFilterMarkup('relationship', filterRelationship)}
      </div>

      <label className="sim-list--filter" style={{ float: 'right' }}>
        <div className="sim-list--filter-label">Opacity based on</div>
        <select
          className="sim-list--filter-option"
          name="opacityOption"
          onChange={onOptionChange}
          value={activeOptions.opacityOption}
        >
          {optionOpacity.map(o => (
            <option value={o.id} key={o.id}>
              {o.name}
            </option>
          ))}
        </select>
      </label>
      <div className="sim-list--name">{simIds.length} sims</div>

      <div className="sim-list--portraits">
        {simIds &&
          simIds
            .sort((a, b) => (SIMS_DATA[a]?.firstname > SIMS_DATA[b]?.firstname ? 1 : -1))
            .map(i => {
              const sim = SIMS_DATA[i];
              if (!sim) {
                return null;
              }
              const household = HOUSEHOLDS_DATA[sim.household];
              const lotId = `${household?.home.world}${household?.home.lot}`;
              const lot = sim.townie ? 'Townie' : LOTS_DATA[lotId];

              return (
                <ListOfSims
                  key={i}
                  options={activeOptions}
                  i={i}
                  currentClub={activeFilters.club}
                  showClub={showClub}
                  showCelebrityLevel={showCelebrityLevel}
                  showDegree={showDegree}
                  showGhost={showGhost}
                  verifyClub={verifyClub}
                  sim={sim}
                  lot={lot}
                />
              );
            })}
      </div>

      {potentialClubMembers.length > 0 && (
        <div className="sim-list--name-alt">Potential new members ({potentialClubMembers.length})</div>
      )}

      <div className="sim-list--portraits">
        {potentialClubMembers &&
          potentialClubMembers
            .sort((a, b) => (SIMS_DATA[a]?.firstname > SIMS_DATA[b]?.firstname ? 1 : -1))
            .map(i => {
              const sim = SIMS_DATA[i];
              if (!sim) {
                return null;
              }

              const household = HOUSEHOLDS_DATA[sim.household];
              const lotId = `${household?.home.world}${household?.home.lot}`;
              const lot = sim.townie ? 'Townie' : LOTS_DATA[lotId];

              return (
                <ListOfSims
                  key={i}
                  options={activeOptions}
                  i={i}
                  currentClub={activeFilters.club}
                  showClub={showClub}
                  verifyClub={verifyClub}
                  showCelebrityLevel={showCelebrityLevel}
                  showDegree={showDegree}
                  showGhost={showGhost}
                  sim={sim}
                  lot={lot}
                />
              );
            })}
      </div>
    </div>
  );
}

export default ListsSims;

const ListOfSims = props => {
  const MAX_SIM_CHECKS = 7;

  const hasError = props.verifyClub ? !filterSimsByPotentialClub(props.currentClub)(props.sim) : false;
  const memberClubs = Object.values(CLUBS_DATA).filter(c => c.members.includes(props.sim._id));
  const simChecked = props.sim?.checked?.base?.replace(/[^1X]/, '').length || 0;

  let checked = false;
  if (props.options.opacityOption === '0') {
    checked = simChecked === MAX_SIM_CHECKS;
  } else if (props.options.opacityOption === '2') {
    const household = HOUSEHOLDS_DATA[props.sim.household] || {};
    const isTownie = household.home?.townie;
    const lotId = `${household.home?.world}${household.home?.lot}${household.home?.flat || ''}`;
    const lot = LOTS_DATA[lotId];
    const hasHosue = Boolean(lot);
    checked = hasHosue || isTownie;
  } else if (props.options.opacityOption === '3') {
    const household = HOUSEHOLDS_DATA[props.sim.household] || {};
    const isTownie = household.home?.townie;
    const lotId = `${household.home?.world}${household.home?.lot}${household.home?.flat || ''}`;
    const lot = LOTS_DATA[lotId];
    const hasHosue = Boolean(lot);
    checked = simChecked === MAX_SIM_CHECKS && (hasHosue || isTownie);
  } else if (props.options.opacityOption === '4') {
    checked = true;
  }

  return (
    <Link to={`/all/${props.i}`} className="sim-list--link">
      <img
        className={`sim-list--profile ${hasError ? 'has-error' : ''}`}
        src={`/images/sims/${props.i}.jpg`}
        alt={props.sim?.firstname}
        style={checked ? null : { opacity: 0.2 }}
      />
      {props.showGhost && props.sim.lifeState?.startsWith('Ghost') && (
        <span className="sim-list--degree">
          {props.sim.lifeState.substring(
            props.sim.lifeState.lastIndexOf('(') + 1,
            props.sim.lifeState.lastIndexOf(')'),
          )}
        </span>
      )}
      {props.showClub && (
        <span className="sim-list--clubs">
          {memberClubs.map(c => (
            <div
              key={c._id}
              className={c.leader === props.sim?._id ? 'sim-list--club sim-list--club-leader' : 'sim-list--club'}
            >
              <img src={`/images/clubs/${c._id}.png`} alt="" className="sim-list--club-icon" />
            </div>
          ))}
        </span>
      )}
      {props.showDegree && props.sim.university && (
        <span
          className={`sim-list--degree ${
            props.sim.university.name === 'Foxbury'
              ? 'is-foxbury'
              : props.sim.university.name === 'Britechester'
              ? 'is-britechester'
              : ''
          }`}
        >
          {props.sim.university?.degree}
        </span>
      )}
      {props.showCelebrityLevel && props.sim.celebrity && (
        <span className="sim-list--celebrity">{props.sim.celebrity}</span>
      )}

      <span className="sim-list--profile-name">{props.sim?.firstname}</span>
    </Link>
  );
};
