import CAREERS_DATA from '../../data/careers.json';
import SIMS_DATA from '../../data/sims.json';

import { formatWeeklyMoney } from './numbers';

export const getWeeklyPayMoney = (career, sim) => {
  if (
    !career ||
    (!career.wages && !career.hourly && !career.gigPay) ||
    sim.lifeState?.startsWith('Ghost') ||
    sim.career?.retired
  ) {
    return 0;
  }

  const hours = career.hours * career.days || 1;
  // const multiplier = (career.fame ? sim.celebrity : 1) || 1;
  const multiplier = 1;
  let wagesIndex = 0;

  if (sim?.university) {
    if (sim.university.honors && sim.university.distinguished) {
      wagesIndex = 4;
    } else if (sim.university.distinguished) {
      wagesIndex = 3;
    } else if (sim.university.honors) {
      wagesIndex = 2;
    } else {
      wagesIndex = 1;
    }
  }

  if (sim?.aspiration === 'Wealthy' || sim?.aspiration === 'Mansion') {
    wagesIndex += 5;
  }

  return (career.wages ? career.wages[wagesIndex] : career.gigPay ? career.gigPay : career.hourly) * hours * multiplier;
};

export const getWeeklyPay = (career, sim) => {
  return formatWeeklyMoney(getWeeklyPayMoney(career, sim));
};

export const getHouseholdWeeklyPay = household => {
  return household.members.reduce(
    (acc, cur) => acc + getWeeklyPayMoney(getSimCareer(SIMS_DATA[cur]?.career), SIMS_DATA[cur]),
    0,
  );
};

export const getSimCareer = simCareer => {
  if (!simCareer) {
    return '';
  }

  const career = CAREERS_DATA[simCareer?.id];

  const careerTitleSuffix = simCareer.level > career.branchLevel || career.agency ? simCareer.branch : '';

  return career.levels
    ? career.levels[`${simCareer.level}${careerTitleSuffix}`]
    : career.agency
    ? career.agency[careerTitleSuffix]
    : {};
};
