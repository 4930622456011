import React from 'react';
import { Link } from 'react-router-dom';

import './LotList.scss';

import LOTS_DATA from '../../data/lots.json';
import HOUSEHOLDS_DATA from '../../data/households.json';
import SIMS_DATA from '../../data/sims.json';
import PETS_DATA from '../../data/pets.json';
import CLUBS_DATA from '../../data/clubs.json';
import { formatNumber } from 'shared/utils/numbers';
import { isResidentalLot } from 'shared/utils/worlds';

function LotList({ lotId }) {
  const currentLot = LOTS_DATA[lotId];

  const resident = Object.values(HOUSEHOLDS_DATA).filter(h => lotId === `${h.home?.world}${h.home?.lot}`)[0];
  const owner = currentLot.owner && HOUSEHOLDS_DATA[currentLot.owner];
  const family = resident || owner;
  const simIds = resident?.members;
  const petIds = resident?.pets;

  const clubs = Object.values(CLUBS_DATA).filter(
    c => lotId === c.meetingLot || c.meetingLot.endsWith(currentLot.type.toLowerCase()),
  );

  return (
    <div className="content lot--content">
      <div className="lot--name">
        {!isResidentalLot(currentLot.type) && (
          <div className="lot--venue-wrapper">
            <img
              src={`/images/icons/venues/${currentLot.type}.png`}
              className="lot--venue"
              alt={currentLot.type}
              title={currentLot.type}
            />
          </div>
        )}
        {currentLot.name}
      </div>
      <div className="lot--home">
        {currentLot.type} | § {formatNumber(currentLot.house)}
        {family && (
          <>
            {' | '}
            <Link to={`/family/${family._id}`}>{family.name}</Link>
          </>
        )}
      </div>
      <div className="lot--description">
        {currentLot.description ? <p>{currentLot.description}</p> : <p>{resident?.description}</p>}
      </div>
      <div className="lot--home">
        <p>Traits: {currentLot.traits.join(', ')}</p>
        {currentLot.theme && <p>Inspiration: {currentLot.theme}</p>}
      </div>
      <div className="lot--portraits">
        {clubs.length > 0 &&
          clubs
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(c => (
              <div key={c._id} className="lot--link">
                <img className="lot--profile is-club" src={`/images/clubs/${c._id}.png`} alt={c?.name} />
                <span className="lot--profile-name">{c?.name}</span>
              </div>
            ))}
        {simIds &&
          simIds
            .sort((a, b) => (SIMS_DATA[a]?.firstname > SIMS_DATA[b]?.firstname ? 1 : -1))
            .map(i => {
              const sim = SIMS_DATA[i];
              if (!sim) {
                return null;
              }

              return (
                <Link to={`/all/${i}`} key={i} className="lot--link">
                  <img className="lot--profile" src={`/images/sims/${i}.jpg`} alt={sim?.firstname} />
                  <span className="lot--profile-name">{sim?.firstname}</span>
                </Link>
              );
            })}

        {petIds &&
          petIds
            .sort((a, b) => (PETS_DATA[a]?.firstname > PETS_DATA[b]?.firstname ? 1 : -1))
            .map(i => {
              const pet = PETS_DATA[i];
              if (!pet) {
                return null;
              }

              return (
                <span key={i} className="lot--link">
                  <img className="lot--profile" src={`/images/pets/${i}.jpg`} alt={pet?.name} />
                  <span className="lot--profile-name">{pet?.name}</span>
                </span>
              );
            })}
      </div>
    </div>
  );
}

export default LotList;
