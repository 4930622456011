import React from 'react';
import { Link } from 'react-router-dom';

import './SimGroup.scss';

import SIMS_DATA from '../../data/sims.json';
import PETS_DATA from '../../data/pets.json';
import CAREERS_DATA from '../../data/careers.json';

function SimGroup({ label, name, url, extraStuff, description, extra, simIds, petIds, showCareerBranch }) {
  let titleSubfix = null;

  if (extraStuff) {
    // titleSubfix = extraStuff.avg;
    titleSubfix = (
      <div style={{ fontSize: '15px', display: 'inline-block', letterSpacing: '1px' }}>
        <span>
          {extraStuff.levels.map(l => (
            <span
              style={{
                width: '7px',
                backgroundColor: '#571845',
                height: `${l ? l * 5 : 1}px`,
                display: 'inline-block',
                marginRight: '2px',
              }}
            ></span>
          ))}
        </span>
        <span style={{ marginLeft: '10px' }}>{extraStuff.avg}</span>
      </div>
    );
  }

  return (
    <div className="sim-group--wrapper">
      {url ? (
        <Link to={url} className="sim-group--name">
          {name}
        </Link>
      ) : (
        <div className="sim-group--name">
          {name || `${simIds.length} sims`}
          {titleSubfix}
        </div>
      )}
      <div className="sim-group--home">{label}</div>
      <div className="sim-group--description">{description}</div>
      <div className="sim-group--portraits">
        {simIds &&
          simIds
            .sort((a, b) => (SIMS_DATA[a]?.firstname > SIMS_DATA[b]?.firstname ? 1 : -1))
            .map(i => {
              const sim = SIMS_DATA[i];
              if (!sim) {
                return null;
              }

              return (
                <Link to={`/all/${i}`} key={i} className="sim-group--link">
                  <img className="sim-group--profile" src={`/images/sims/${i}.jpg`} alt={sim?.firstname} />
                  {showCareerBranch && sim.career?.level && (
                    <span className="sim-group--level">{sim.career?.level}</span>
                  )}
                  {showCareerBranch && sim.career?.branch && (
                    <img
                      src={`/images/icons/careers/${sim.career?.id}_${sim.career?.branch}i.png`}
                      alt={CAREERS_DATA[sim.career?.id]?.name}
                      className="sim-group--branch-icon"
                    />
                  )}
                  <span className="sim-group--profile-name">{sim?.firstname}</span>
                </Link>
              );
            })}
        {petIds &&
          petIds
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(
              i =>
                PETS_DATA[i] && (
                  <div key={i}>
                    <img className="sim-group--profile" src={`/images/pets/${i}.jpg`} alt={PETS_DATA[i]?.name} />
                    <span className="sim-group--profile-name">{PETS_DATA[i]?.name}</span>
                  </div>
                ),
            )}
      </div>
      <div className="sim-group--extra">{extra}</div>
    </div>
  );
}

export default SimGroup;
