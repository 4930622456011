import React from 'react';
import { Link } from 'react-router-dom';

import './ProfileTop.scss';

import TRAITS_DATA from '../../data/traits.json';
import CLUBS_DATA from '../../data/clubs.json';
import ASPIRATIONS_DATA from '../../data/aspirations.json';
import CAREERS_DATA from '../../data/careers.json';
import AGES_DATA from '../../data/ages.json';

import { getWeeklyPay, getSimCareer } from '../../shared/utils/calculations';

function ProfileTop({ sim, shortVersion }) {
  if (!sim) {
    return null;
  }

  const simCareer = sim.career || {};
  const career = CAREERS_DATA[sim.career?.id] || {};
  const sexualities = {
    bis: 'Bisexual',
    ace: 'Asexual',
    het: 'Heterosexual',
    hom: 'Homosexual',
  };

  const careerName = career.name;
  const careerBranch = simCareer.branch && career.branches ? career.branches[simCareer.branch] : {};
  const branchName = careerBranch?.name;

  const careerLevel = getSimCareer(sim.career);

  const careerTitle = careerLevel?.name || simCareer.name || '';

  const careerBranchId = simCareer.branch ? `${simCareer.id}_${simCareer.branch}` : simCareer?.id;

  const celbertyList = Array.from('x'.repeat(sim.celebrity));
  const stars = celbertyList.map(a => '★').join('');
  const genderIcon = sim.gender === 'Male' ? '♂ ' : '♀ ';

  const clubs = Object.values(CLUBS_DATA).filter(c => c.members.includes(sim._id));

  return (
    <div className="content profile--wrapper">
      <div>
        <img src={`/images/sims/${sim._id}.jpg`} alt={`${sim.firstname} ${sim.lastname}`} className="profile--image" />
        {!shortVersion && (
          <div className="profile--tags">
            <div className="profile--origin">{sim.origin}</div>

            <div className="profile--celebrity">{stars}</div>

            <div className="profile--age">
              {genderIcon}
              <span>{AGES_DATA[sim.ageCode]?.name || sim.ageCode}</span>
            </div>
          </div>
        )}
      </div>
      <div className="profile--details">
        <h1 className="profile--name">
          <div>{sim.firstname}</div>
          <div>{sim.lastname}</div>
        </h1>
        {shortVersion && (
          <div className="u-small">
            {sim.origin} | {AGES_DATA[sim.ageCode]?.name} | {genderIcon}
            {stars.length > 0 ? ` | ${stars}` : ''}
          </div>
        )}
        {sim.lifeState && <div className="u-small">{sim.lifeState}</div>}
        <div className="profile--traits">
          <div className="profile--traits-col">
            {sim.traits &&
              sim.traits.map(t => (
                <div key={t} className="profile--trait">
                  <img src={`/images/icons/traits/${t}.png`} alt="" className="profile--trait-icon" />
                  <span>{TRAITS_DATA[t]?.name}</span>
                </div>
              ))}
          </div>
          <div className="profile--traits-col">
            {sim.sexuality && (
              <div className="profile--trait">
                <img
                  src={`/images/icons/lgbt/${
                    sim.sexuality === 'hom' ? (sim.gender === 'Female' ? 'leb' : 'gay') : sim.sexuality
                  }.png`}
                  alt=""
                  className="profile--trait-icon"
                />
                <span>{sexualities[sim.sexuality]}</span>
              </div>
            )}
            {clubs &&
              clubs.map(c => (
                <div key={c._id} className="profile--club">
                  <img src={`/images/clubs/${c._id}.png`} alt="" className="profile--club-icon" />
                  <span>
                    {c.leader === sim._id ? '⭐️ ' : ''}
                    {c.name}
                  </span>
                </div>
              ))}

            {shortVersion && sim.aspiration && (
              <div className="profile--club">
                <img
                  src={`/images/icons/aspirations/${sim.aspiration}.png`}
                  alt={ASPIRATIONS_DATA[sim.aspiration]?.name}
                  className="profile--club-icon"
                />
                {ASPIRATIONS_DATA[sim.aspiration]?.name}
              </div>
            )}

            {shortVersion && sim.career && (
              <div className="profile--club">
                <img
                  src={`/images/icons/careers/${careerBranchId}.png`}
                  alt={CAREERS_DATA[sim.career?.id]?.name}
                  className="profile--club-icon"
                />
                <div className="u-small">
                  {Boolean(branchName) ? (
                    <div>
                      {careerTitle}
                      <div>
                        {simCareer.level} - {branchName} - {careerName}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>{careerTitle}</div>
                      <div>
                        {simCareer.level ? 'Level' : ''} {simCareer.level} {careerName}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {shortVersion && sim.university && (
              <div className="profile--trait">
                <img
                  src={`/images/icons/degree${sim.university.distinguished ? 'Dis' : ''}.png`}
                  alt=""
                  className="profile--trait-icon"
                />
                <span>
                  {sim.university.honors ? '★ ' : ''}
                  {sim.university.degree}
                </span>
              </div>
            )}
          </div>
        </div>

        {!shortVersion && sim.aspiration && (
          <div className="profile--aspiration">
            <img
              src={`/images/icons/aspirations/${sim.aspiration}.png`}
              alt={ASPIRATIONS_DATA[sim.aspiration]?.name}
              className="profile--aspiration-icon"
            />
            {ASPIRATIONS_DATA[sim.aspiration]?.name}
          </div>
        )}

        {!shortVersion && sim.career && (
          <div className="profile--career">
            <img
              src={`/images/icons/careers/${careerBranchId}.png`}
              alt={CAREERS_DATA[sim.career?.id]?.name}
              className="profile--career-icon"
            />
            <div className="profile--career-text">
              <div className="profile--career-title" title={simCareer.description}>{careerTitle}</div>
              {Boolean(branchName) ? (
                <div>
                  {simCareer.level ? 'Level' : ''} {simCareer.level} {branchName} ({careerName})
                </div>
              ) : (
                <div>
                  {simCareer.level ? 'Level' : ''} {simCareer.level} {careerName}
                </div>
              )}

              <div>
                {sim.lifeState?.startsWith('Ghost')
                  ? `Ghosts don't work anymore.`
                  : sim.career?.retired
                  ? `${sim.firstname} is retired.`
                  : simCareer.id === 'SEL' || simCareer.id === 'OWN' || simCareer.id === 'FRE'
                  ? `${sim.firstname} makes ${sim.gender === 'Female' ? 'her' : 'his'} own money.`
                  : simCareer.id === 'STU'
                  ? ''
                  : getWeeklyPay(careerLevel, sim)}
              </div>
            </div>
          </div>
        )}
        {!shortVersion && sim.university && (
          <div className="profile--degree">
            <img
              src={`/images/icons/degree${sim.university.distinguished ? 'Dis' : ''}.png`}
              alt=""
              className="profile--degree-icon"
            />
            <span>
              {sim.university.honors ? '★ ' : ''}
              {sim.university.degree}
            </span>
          </div>
        )}

        {shortVersion && <div className="profile--introduction">{sim.description}</div>}

        {shortVersion && (
          <Link to={`/all/${sim._id}`} className="u-small">
            Show profile &gt;
          </Link>
        )}
      </div>
    </div>
  );
}

export default ProfileTop;
