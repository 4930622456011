import React from 'react';

import './Ages.scss';

import SIMS_DATA from '../data/sims.json';

import SimGroup from 'sections/lists/SimGroup';

function Ages() {
  const ageList = ['0b', '1t', '2c', '3t', '4ya', '5a', '6e'];
  const allSims = Object.values(SIMS_DATA);

  const constructured = ageList.map(a => {
    const sims = allSims.filter(s => s.ageCode === a);
    const firstSim = sims[0];

    return {
      label: null,
      name: firstSim?.age,
      url: null,
      description: null,
      simIds: sims.map(s => s._id),
      petIds: null,
    };
  });

  return (
    <main className="ages--wrapper">
      <div className="content ages--title">
        <h1>Ages</h1>
      </div>

      <div className="content">
        {constructured.map(c => (
          <SimGroup key={c.name} {...c} />
        ))}
      </div>
    </main>
  );
}

export default Ages;
