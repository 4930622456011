import React from 'react';

import './Clubs.scss';

import CLUBS_DATA from '../data/clubs.json';
import SIMS_DATA from '../data/sims.json';
import LOTS_DATA from '../data/lots.json';
import WORLDS_DATA from '../data/worlds.json';

import SimGroup from 'sections/lists/SimGroup';

function Clubs() {
  const clubsList = Object.values(CLUBS_DATA).sort((a, b) => (a.name > b.name ? 1 : -1));

  const constructured = clubsList.map(club => {
    const leader = SIMS_DATA[club.leader] || {};

    const meetingLotText = club.meetingLot?.startsWith('Any ') ? club.meetingLot : '';
    const meetingLot = LOTS_DATA[club.meetingLot];
    const worldId = club.meetingLot.replace(/[0-9]/g, '');
    const world = WORLDS_DATA[worldId];

    return {
      label: `${
        meetingLotText
          ? meetingLotText
          : meetingLot
          ? `${meetingLot.name} (${meetingLot.type} in ${world?.name})`
          : club.meetingLot
          ? 'Unknown lot'
          : 'Not set'
      } | ${leader.firstname} ${leader.lastname}`,
      name: (
        <div>
          <img src={`/images/clubs/${club._id}.png`} alt={`${club.name} icon`} className="clubs--icon" />
          {club.secret ? '♦ ' : ''}
          {club.name}
        </div>
      ),
      url: 1,
      description: club.description,
      extra: club.requirements.map(r => r.value).join(' —— '),
      simIds: club.members.sort(),
      petIds: null,
      club: club,
    };
  });

  return (
    <main className="clubs--wrapper">
      <div className="content families--title">
        <h1>Club</h1>
      </div>

      <div className="content">
        {constructured.map(c => (
          <>
            <SimGroup key={c.name} {...c} />
            <div style={{ display: 'flex' }}>
              <ul style={{ margin: '-50px 0px 50px' }}>
                {c.club.positiveActivity?.map(s => (
                  <li>{s}</li>
                ))}
              </ul>
              <ul style={{ margin: '-50px 0px 50px' }}>
                {c.club.negativeActivity?.map(s => (
                  <li>{s}</li>
                ))}
              </ul>
            </div>
          </>
        ))}
      </div>
    </main>
  );
}

export default Clubs;
