import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './Families.scss';

import HOUSEHOLDS_DATA from '../data/households.json';
import SIMS_DATA from '../data/sims.json';
import LOTS_DATA from '../data/lots.json';
import WORLDS_DATA from '../data/worlds.json';

import { formatNumber, formatWeeklyMoney } from 'shared/utils/numbers';
import { getHouseholdWeeklyPay } from 'shared/utils/calculations';

function Families() {
  const [sortOrder, setSortOrder] = useState(0);
  const [filter, setFilter] = useState(0);

  const sortOrders = [
    { id: 0, name: 'Alphabetical' },
    { id: 1, name: 'House' },
    { id: 2, name: 'Funds' },
    { id: 3, name: 'Income' },
    { id: 4, name: 'Rent' },
    // { id: 5, name: 'Savings' },
  ];

  const filterWorlds = [
    { id: 0, name: 'Any' },
    { id: 1, name: 'No world' },
  ].concat(
    Object.values(WORLDS_DATA).map(a => ({
      id: a._id,
      name: a.name,
    })),
  );

  const getSortingFunction = s => {
    if (sortOrder === 0) {
      return (a, b) => (a.name > b.name ? 1 : -1);
    }

    if (sortOrder === 1) {
      return (a, b) => {
        const lotIdA = `${a.home.world}${a.home.lot}${a.home.flat || ''}`;
        const lotIdB = `${b.home.world}${b.home.lot}${b.home.flat || ''}`;

        const lotA = LOTS_DATA[lotIdA] || {};
        const lotB = LOTS_DATA[lotIdB] || {};

        return (lotA.house || 0) + (lotA.value || 0) < (lotB.house || 0) + (lotB.value || 0) ? 1 : -1;
      };
    }

    if (sortOrder === 2) {
      return (a, b) => ((a.funds || 0) < (b.funds || 0) ? 1 : -1);
    }

    if (sortOrder === 3) {
      return (a, b) => ((getHouseholdWeeklyPay(a) || 0) < (getHouseholdWeeklyPay(b) || 0) ? 1 : -1);
    }

    if (sortOrder === 4) {
      return (a, b) => {
        const lotIdA = `${a.home.world}${a.home.lot}${a.home.flat || ''}`;
        const lotIdB = `${b.home.world}${b.home.lot}${b.home.flat || ''}`;

        const lotA = LOTS_DATA[lotIdA] || {};
        const lotB = LOTS_DATA[lotIdB] || {};

        return (lotA.lotTaxes || 0) + (lotA.rent || 0) < (lotB.lotTaxes || 0) + (lotB.rent || 0) ? 1 : -1;
      };
    }
  };

  const householdsList = Object.values(HOUSEHOLDS_DATA)
    .filter(h => {
      if (filter === 0) {
        return true;
      }
      if (filter === 1) {
        return !h?.home?.world;
      }

      return filter === h.home.world;
    })
    .sort((a, b) => (a.name < b.name ? 1 : -1))
    .sort(getSortingFunction());

  const getSortingValue = household => {
    const lotId = `${household.home.world}${household.home.lot}${household.home.flat || ''}`;
    const lot = LOTS_DATA[lotId] || {};

    if (sortOrder === 0) {
      if (filter) {
        return household.home.townie ? 'Townie' : lot.name;
      }

      return SIMS_DATA[household.members[0]]?.origin;
    }

    if (sortOrder === 1) {
      return lot.house || lot.value ? `§ ${formatNumber((lot.house || 0) + (lot.value || 0))}` : '';
    }

    if (sortOrder === 2) {
      return household.funds
        ? `§ ${formatNumber(household.funds)}${household.vault ? `(§ ${formatNumber(household.vault)})` : ''}`
        : '';
    }

    if (sortOrder === 3) {
      return formatWeeklyMoney(getHouseholdWeeklyPay(household));
    }

    if (sortOrder === 4) {
      const lotId = `${household.home.world}${household.home.lot}${household.home.flat || ''}`;
      const lot = LOTS_DATA[lotId] || {};

      return lot.lotTaxes || lot.rent ? `§ ${formatNumber((lot.lotTaxes || 0) + (lot.rent || 0))}` : '';
    }

    return '';
  };

  return (
    <main className="families--wrapper">
      <div className="content families--title">
        <h1>By family</h1>
      </div>

      <div className="families--sorting-box">
        {sortOrders.map(f => (
          <button
            key={f.id}
            className={`families--button ${sortOrder === f.id ? 'is-active' : ''}`}
            onClick={() => setSortOrder(f.id)}
          >
            {f.name}
          </button>
        ))}
      </div>

      <div className="families--sorting-box">
        {filterWorlds.map(f => (
          <button
            key={f.id}
            className={`families--button ${filter === f.id ? 'is-active' : ''}`}
            onClick={() => setFilter(f.id)}
          >
            {f.name}
          </button>
        ))}
      </div>

      <div className="content is-wide families--content">
        {householdsList.map(h => {
          return (
            <div className="families--item" key={h._id}>
              <div className="families--profile">
                {h.members.map(m => (
                  <img
                    src={`/images/sims/${m}.jpg`}
                    alt={h.name}
                    key={m}
                    className="families--image"
                    width={`${100 / h.members.length}%`}
                  />
                ))}
              </div>
              <div>
                <div className="families--name">
                  <Link to={`/family/${h._id}`}>{h.name}</Link>
                </div>
                <div className="families--sorting">{getSortingValue(h)}</div>
              </div>
            </div>
          );
        })}
      </div>
    </main>
  );
}

export default Families;
