import React, { useState } from 'react';

import './Aspirations.scss';

import SIMS_DATA from '../data/sims.json';
import ASPIRATIONS_DATA from '../data/aspirations.json';

import SimGroup from 'sections/lists/SimGroup';

function Aspirations() {
  const [sortOrder, setSortOrder] = useState(0);
  const [filter, setFilter] = useState([]);

  const sortOrders = [
    { id: 0, name: 'Alphabetical' },
    { id: 1, name: 'Increasing sims' },
    { id: 2, name: 'Decreasing sims' },
  ];

  const getSortingFunction = forceAlpha => {
    if (forceAlpha || sortOrder === 0) {
      return (a, b) =>
        ASPIRATIONS_DATA[a].name > ASPIRATIONS_DATA[b].name ? 1 : -1;
    }

    if (sortOrder === 1) {
      return (a, b) => {
        const simsA = Object.values(SIMS_DATA).filter(s => s.aspiration === a);
        const simsB = Object.values(SIMS_DATA).filter(s => s.aspiration === b);

        return simsA.length > simsB.length ? 1 : -1;
      };
    }

    if (sortOrder === 2) {
      return (a, b) => {
        const simsA = Object.values(SIMS_DATA).filter(s => s.aspiration === a);
        const simsB = Object.values(SIMS_DATA).filter(s => s.aspiration === b);

        return simsA.length < simsB.length ? 1 : -1;
      };
    }
  };

  const aspir = Object.keys(ASPIRATIONS_DATA)
    .filter(a =>
      filter.length > 0 ? filter.includes(ASPIRATIONS_DATA[a].category) : true,
    )
    .sort(getSortingFunction(true))
    .sort(getSortingFunction());

  const categories = [
    ...new Set(Object.values(ASPIRATIONS_DATA).map(a => a.category)),
  ].sort();

  const constructured = aspir.map(a => {
    const asp = ASPIRATIONS_DATA[a];
    const sims = Object.values(SIMS_DATA)
      .filter(s => s.aspiration === a)
      .map(s => s._id);

    return {
      label: asp.group + ' / ' + asp.category,
      name: `${asp.name} (${sims.length})`,
      url: null,
      description: asp.description,
      simIds: sims,
      petIds: null,
    };
  });

  const setFilterHelper = id => {
    if (filter.includes(id)) {
      setFilter([...filter].filter(f => f !== id));
      return;
    }

    setFilter([...filter, id]);
  };

  return (
    <main className="aspirations--wrapper">
      <div className="content aspirations--title">
        <h1>Aspirations</h1>
      </div>

      <div className="families--sorting-box">
        {sortOrders.map(f => (
          <button
            key={f.id}
            className={`families--button ${
              sortOrder === f.id ? 'is-active' : ''
            }`}
            onClick={() => setSortOrder(f.id)}
          >
            {f.name}
          </button>
        ))}
      </div>

      <div className="content">
        <div>
          {categories.map(s => (
            <button
              key={s}
              className={`families--button ${
                filter.includes(s) ? 'is-active' : ''
              }`}
              onClick={() => setFilterHelper(s)}
            >
              {s}
            </button>
          ))}
        </div>
        <div>
          <button className={`families--button`} onClick={() => setFilter([])}>
            Reset filtering
          </button>
        </div>
      </div>

      <div className="content">
        {constructured.map(c => (
          <SimGroup key={c.name} {...c} />
        ))}
      </div>
    </main>
  );
}

export default Aspirations;
