import React from 'react';

import SIMS_DATA from '../../data/sims.json';
import HOUSEHOLDS_DATA from '../../data/households.json';
import CLUBS_DATA from '../../data/clubs.json';
import CAREERS_DATA from '../../data/careers.json';
import LOTS_DATA from '../../data/lots.json';

export const getRelationsTitle = (currentSimId, relatedSimId, relation) => {
  if (!relatedSimId || !currentSimId) {
    return null;
  }

  const relatedSim = SIMS_DATA[relatedSimId];
  const currentSim = SIMS_DATA[currentSimId];

  if (!currentSim) {
    return null;
  }

  const pet = getPetRelation(currentSim, relatedSimId, relation);

  if (!relatedSim) {
    return [pet].filter(Boolean);
  }

  const romance = getRomanticRelation(relation);
  const location = getLocationRelation(currentSim, relatedSim, relation);
  const club = getClubRelation(currentSim, relatedSim);
  const career = getCareerRelation(currentSim, relatedSim);
  const title = getTitleRelation(relation);
  const university = getUniversityRelation(currentSim, relatedSim);
  const family = getFamilyRelation(currentSim, relatedSim);
  const occult = getOccultRelation(currentSim, relatedSim);

  return [
    romance,
    title,
    family,
    location,
    career,
    university,
    club,
    pet,
    occult,
  ].filter(Boolean);
};

const getRomanticRelation = relation => {
  const title = relation.family;

  return title === 'Married' ? (
    <span key="rom" aria-label={title} title={title} role="img">
      💍
    </span>
  ) : title === 'Divorced' ? (
    <span key="rom" aria-label={title} title={title} role="img">
      💔
    </span>
  ) : title === 'Dating' ? (
    <span key="rom" aria-label={title} title={title} role="img">
      ❤️
    </span>
  ) : title === 'Ex-partners' ? (
    <span key="rom" aria-label={title} title={title} role="img">
      💔
    </span>
  ) : (
    title
  );
};

const getTitleRelation = relation => {
  const title = relation.title;

  return title === 'Enemy' ? (
    <span key="tit" aria-label={title} title={title} role="img">
      👿
    </span>
  ) : (
    title
  );
};

const getLocationRelation = (currentSim, relatedSim) => {
  const currentHousehold = HOUSEHOLDS_DATA[currentSim.household];
  const relatedHousehold = HOUSEHOLDS_DATA[relatedSim.household];

  const currentHome = currentHousehold?.home;
  const relatedHome = relatedHousehold?.home;

  const currentLotId = `${currentHome?.world}${currentHome?.lot}${
    currentHome?.flat || ''
  }`;
  const relatedLotId = `${relatedHome?.world}${relatedHome?.lot}${
    relatedHome?.flat || ''
  }`;

  const currentLot = LOTS_DATA[currentLotId];
  const relatedLot = LOTS_DATA[relatedLotId];

  return currentSim.household === relatedSim.household ? (
    <span
      key="loc"
      aria-label="Household member"
      title="Household member"
      role="img"
    >
      🏡
    </span>
  ) : currentLot &&
    !currentHome.townie &&
    currentLot.neighbourhood === relatedLot?.neighbourhood ? (
    <span key="loc" aria-label="Neighbour" title="Neighbour" role="img">
      🏘
    </span>
  ) : (
    ''
  );
};

const getClubRelation = (currentSim, relatedSim) => {
  const currentClubs = Object.values(CLUBS_DATA)
    .filter(c => c.members.includes(currentSim._id))
    .filter(c => c.members.includes(relatedSim._id));

  if (currentClubs.length > 0) {
    return (
      <span
        key="clu"
        aria-label={currentClubs.map(c => c.name)}
        title={currentClubs.map(c => c.name)}
        role="img"
      >
        👥
      </span>
    );
  }

  return null;
};

const getOccultRelation = (currentSim, relatedSim) => {
  if (currentSim.lifeState && currentSim.lifeState === relatedSim.lifeState) {
    return (
      <span
        key="occ"
        aria-label={currentSim.lifeState}
        title={currentSim.lifeState}
        role="img"
      >
        ✨
      </span>
    );
  }

  return null;
};

const getPetRelation = (currentSim, petId, relation) => {
  if (!relation.isPet) {
    return null;
  }

  const pet = (
    <span key="pet" aria-label="A pet" title="A pet" role="img">
      🐈
    </span>
  );

  const family = HOUSEHOLDS_DATA[currentSim.household];
  const pets = family.pets;

  const household = pets.includes(petId) ? (
    <span key="loc" aria-label="Household pet" title="Household pet" role="img">
      🏡
    </span>
  ) : null;

  return [pet, household].filter(Boolean);
};

const getCareerRelation = (relatedSim, currentSim) => {
  const relatedJob = relatedSim?.career?.id;
  const currentJob = currentSim?.career?.id;

  if (!relatedJob || !currentJob) {
    return null;
  }

  if (relatedJob !== currentJob) {
    return null;
  }

  if (currentJob === 'STU' && relatedSim.ageCode !== currentSim.ageCode) {
    return null;
  }

  if (currentJob === 'STU') {
    return (
      <span key="car" aria-label="Classmates" title="Classmates" role="img">
        📝
      </span>
    );
  }

  const jobName = CAREERS_DATA[currentJob]?.name;

  return (
    <span
      key="car"
      aria-label={`Co-worker at ${jobName}`}
      title={`Co-worker at ${jobName}`}
      role="img"
    >
      💼
    </span>
  );
};

const getUniversityRelation = (currentSim, relatedSim) => {
  if (relatedSim.ageCode !== currentSim.ageCode) {
    return null;
  }

  if (!relatedSim.university || !currentSim.university) {
    return null;
  }

  if (relatedSim.university.name !== currentSim.university.name) {
    return null;
  }

  return (
    <span
      key="uni"
      aria-label={`Studied at ${currentSim?.university.name}`}
      title={`Studied at ${currentSim?.university.name}`}
      role="img"
    >
      🎓
    </span>
  );
};

const getFamilyRelation = (currentSim, relatedSim) => {
  const simFamily = currentSim.family;
  const relatedFamily = relatedSim.family;

  // Neither sim has any family relations
  if (!relatedFamily && !simFamily) {
    return null;
  }

  // currentSim is the parent of relatedSim
  if (
    relatedFamily?.mother === currentSim._id ||
    relatedFamily?.father === currentSim._id
  ) {
    return (
      <span key="fam" aria-label="Child" title="Child" role="img">
        🧒
      </span>
    );
  }

  // relatedSim is the parent of currentSim
  if (
    simFamily?.mother === relatedSim._id ||
    simFamily?.father === relatedSim._id
  ) {
    return (
      <span key="fam" aria-label="Parent" title="Parent" role="img">
        🧑
      </span>
    );
  }

  // Can only  siblings if they actualy have parents
  if (
    (simFamily?.mother || simFamily?.father) &&
    (relatedFamily?.mother || relatedFamily?.father)
  ) {
    // relatedSim and currentSim have the same two parents
    if (
      simFamily?.mother === relatedFamily?.mother &&
      simFamily?.father === relatedFamily?.father
    ) {
      return (
        <span key="fam" aria-label="Sibling" title="Sibling" role="img">
          👨‍👧‍👦
        </span>
      );
    }

    // relatedSim and currentSim have on parent in common
    if (
      simFamily?.mother === relatedFamily?.mother ||
      simFamily?.father === relatedFamily?.father
    ) {
      return (
        <span
          key="fam"
          aria-label="Half-sibling"
          title="Half-sibling"
          role="img"
        >
          👨‍👧‍👦
        </span>
      );
    }
  }

  // relatedSim is the grandchild of currentSim
  const relatedMother = relatedFamily?.mother;
  const relatedFather = relatedFamily?.father;

  if (relatedMother || relatedFather) {
    const motherFamily = SIMS_DATA[relatedMother]?.family;
    const fatherFamily = SIMS_DATA[relatedFather]?.family;
    const grandchild = (
      <span key="fam" aria-label="Grandchild" title="Grandchild" role="img">
        👶
      </span>
    );

    if (
      motherFamily?.mother === currentSim._id ||
      motherFamily?.father === currentSim._id
    ) {
      return grandchild;
    }

    if (
      fatherFamily?.mother === currentSim._id ||
      fatherFamily?.father === currentSim._id
    ) {
      return grandchild;
    }
  }

  // relatedSim is the grandparent of currentSim
  const simMother = simFamily?.mother;
  const simFather = simFamily?.father;

  if (simMother || simFather) {
    const motherFamily = SIMS_DATA[simMother]?.family;
    const fatherFamily = SIMS_DATA[simFather]?.family;
    const grandchild = (
      <span key="fam" aria-label="Grandparent" title="Grandparent" role="img">
        🧓
      </span>
    );

    if (
      motherFamily?.mother === relatedSim._id ||
      motherFamily?.father === relatedSim._id
    ) {
      return grandchild;
    }

    if (
      fatherFamily?.mother === relatedSim._id ||
      fatherFamily?.father === relatedSim._id
    ) {
      return grandchild;
    }
  }

  // TODO: Cousins
  // TODO: Son/Mother/Daughter/Father-in-law
  // TODO: Aunt/Uncle
  // TODO: Niece/Nephew
  // TODO: Siblings without parents? Or should ALL siblings have at least one parent?
  // TODO: Siblings with only one known parent, call them siblings instead of half-siblings.

  return null;
};
