import HOUSEHOLDS_DATA from '../../data/households.json';
import SIMS_DATA from '../../data/sims.json';
import AGES_DATA from '../../data/ages.json';
import CLUBS_DATA from '../../data/clubs.json';
import RELATIONS_DATA from '../../data/relations.json';

export const filterSimsByOrigin = value => s => {
  if (!value || value === '0') {
    return true;
  }

  if (value === '1') {
    return s.origin === 'Sims 4';
  }

  if (value === '2') {
    return s.origin === 'DLC';
  }

  if (value === '3') {
    return s.origin === 'Sims 3';
  }

  if (value === '4') {
    return s.origin === 'Prev. Sims';
  }

  if (value === '5') {
    return s.origin === 'Custom';
  }
};

export const filterSimsByLifeState = value => s => {
  if (!value || value === '0') {
    return true;
  }

  if (value === '1') {
    return !s.lifeState;
  }

  if (value === '2') {
    return s.lifeState === 'Vampire';
  }

  if (value === '3') {
    return s.lifeState === 'Spellcaster';
  }

  if (value === '4') {
    return s.lifeState?.startsWith('Ghost');
  }

  if (value === '5') {
    return s.lifeState === 'Mermaid';
  }

  if (value === '6') {
    return s.lifeState === 'Alien';
  }

  if (value === '7') {
    return s.lifeState === 'PlantSim';
  }

  if (value === '8') {
    return s.lifeState === 'Servo';
  }
};

export const filterSimsByAchievement = value => s => {
  if (!value || value === '0') {
    return true;
  }

  if (value === '1') {
    return s.university;
  }

  if (value === '2') {
    return s.celebrity;
  }

  if (value === '3') {
    const simId = s._id;
    const isParent = Object.values(SIMS_DATA).some(s =>
      s.family ? Object.values(s.family).some(f => f === simId) : false,
    );

    return isParent;
  }

  const householdId = s.household;
  const household = HOUSEHOLDS_DATA[householdId];

  if (value === '4') {
    return household?.funds > 150000;
  }
};

export const filterSimsByAge = value => s => {
  if (!value || value === '0') {
    return true;
  }

  if (value === '4ya') {
    return s.ageCode === value || s.ageCode === '4nm' || s.ageCode === '4om';
  }

  return s.ageCode === value;
};

export const filterSimsByWorld = value => s => {
  if (!value || value === '0') {
    return true;
  }

  const householdId = s.household;
  const household = HOUSEHOLDS_DATA[householdId];

  if (value === '1') {
    return household?.home?.townie;
  }

  if (value === '2') {
    return !household?.home?.world;
  }

  return household?.home?.world === value && !household?.home?.townie;
};

export const filterSimsByClub = value => s => {
  if (!value || value === '0') {
    return true;
  }

  if (value === '1') {
    const allClubs = Object.values(CLUBS_DATA);

    return allClubs.filter(c => c?.members.includes(s._id)).length > 0;
  }

  if (value === '2') {
    return false;
  }

  const club = CLUBS_DATA[value];

  return club?.members.includes(s._id);
};

export const filterSimsByExcludingClub = value => s => {
  if (!value || value === '0') {
    return true;
  }

  if (value === '1') {
    return false;
  }

  const club = CLUBS_DATA[value];

  return !club?.members.includes(s._id);
};

export const filterSimsByPotentialClub = value => s => {
  if (!value || value === '0') {
    return false;
  }

  if (value === '1') {
    return false;
  }

  const club = CLUBS_DATA[value];

  // Invalid club ID
  if (!club) {
    return false;
  }

  const reqList = club.requirements;

  const checkedReq = reqList.map(r => {
    if (r.type === 'age') {
      const allOptions = r.value.split(', ');

      const checkedOptions = allOptions.map(a => {
        if (a === AGES_DATA['4ya'].name) {
          return s.ageCode === '4ya' || s.ageCode === '4om' || s.ageCode === '4nm';
        }

        return a === AGES_DATA[s.ageCode]?.name;
      });

      return checkedOptions.some(Boolean);
    }

    if (r.type === 'trait') {
      const allOptions = r.value.split(', ');

      const checkedOptions = allOptions.map(a => {
        return s.traits?.includes(a);
      });

      return checkedOptions.some(Boolean);
    }

    if (r.type === 'gender') {
      return s.gender === r.value;
    }

    if (r.type === 'skill') {
      const allOptions = r.value.split(', ');

      const checkedOptions = allOptions.map(a => {
        return s.skills?.map(s => s.skill).includes(a);
      });

      return checkedOptions.some(Boolean);
    }

    if (r.type === 'sexuality') {
      const allOptions = r.value.split(', ');

      const checkedOptions = allOptions.map(a => {
        return s.sexuality === a;
      });

      return checkedOptions.some(Boolean);
    }

    if (r.type === 'job') {
      const allOptions = r.value.split(', ');

      const checkedOptions = allOptions.map(a => {
        return s.career?.id === a;
      });

      return checkedOptions.some(Boolean);
    }

    if (r.type === 'celebrity') {
      const allOptions = r.value.split(', ');

      const checkedOptions = allOptions.map(a => {
        return String(s.celebrity) === a;
      });

      return checkedOptions.some(Boolean);
    }

    if (r.type === 'life') {
      const allOptions = r.value.split(', ');

      const checkedOptions = allOptions.map(a => {
        return s.lifeState?.startsWith(a);
      });

      return checkedOptions.some(Boolean);
    }

    if (r.type === 'status') {
      // TODO: Check if married or not
      return true;
    }

    if (r.type === 'funds') {
      const householdId = s.household;
      const household = HOUSEHOLDS_DATA[householdId];

      if (r.value === 'Rich') {
        return household?.funds > 150000;
      }

      return true;
    }

    return false;
  });

  return checkedReq.every(Boolean);
};

export const filterSimsBySexuality = value => s => {
  if (!value || value === '0') {
    return true;
  }

  const isChild = s.ageCode === '0b' || s.ageCode === '1t' || s.ageCode === '2c';

  if (value === '1') {
    return !s.sexuality && !isChild;
  }

  if (value === '2') {
    return isChild;
  }

  if (value === '3') {
    return s.sexuality === 'bis' || s.sexuality === 'hom';
  }

  return s.sexuality === value;
};

export const filterSimsByGender = value => s => {
  if (!value || value === '0') {
    return true;
  }

  return s.gender === value;
};

export const filterSimsByRelationship = value => s => {
  if (!value || value === '0') {
    return true;
  }

  const allRelations = Object.values(RELATIONS_DATA).filter(r => r._id.startsWith(s._id) || r._id.endsWith(s._id));

  if (value === '1') {
    const dating = allRelations.filter(r => r.family === 'Dating' || r.family === 'Engaged' || r.family === 'Married');
    return dating.length === 0;
  }

  if (value === '2') {
    const dating = allRelations.filter(r => r.family === 'Dating');
    return dating.length > 0;
  }

  if (value === '3') {
    const engaged = allRelations.filter(r => r.family === 'Engaged');
    return engaged.length > 0;
  }

  if (value === '4') {
    const married = allRelations.filter(r => r.family === 'Married');
    return married.length > 0;
  }

  return false;
};

export const filterSimsByTrait = value => s => {
  if (!value || value === '0') {
    return true;
  }

  return s.traits && s.traits.includes(value);
};

export const parseFilterFromString = url => {
  const filters = url.split('&');
  const result = {};

  filters.forEach(f => {
    const pair = f.split('=');

    result[pair[0]] = pair[1];
  });

  return result;
};

export const parseFilterToString = filters => {
  return Object.keys(filters)
    .map(k => `${k}=${filters[k]}`)
    .join('&');
};
