import React, { useState } from 'react';

import './Careers.scss';

import SIMS_DATA from '../data/sims.json';
import CAREERS_DATA from '../data/careers.json';

import SimGroup from 'sections/lists/SimGroup';
import { getWeeklyPayMoney, getSimCareer } from 'shared/utils/calculations';
import { formatNumber, formatWeeklyMoney } from 'shared/utils/numbers';

function Careers() {
  const [sortOrder, setSortOrder] = useState(0);
  const [filter, setFilter] = useState(1);
  // const MAX_CAREER_SIMS = 6;
  const MAX_CAREER_SIMS = 5;

  const sortOrders = [
    { id: 0, name: 'Alphabetical' },
    { id: 1, name: 'Increasing sims' },
    { id: 2, name: 'Decreasing sims' },
    { id: 3, name: 'Highest weekly pay' },
    { id: 4, name: 'Avg. Level' },
  ];

  const filters = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Hide lone-careers' },
    { id: 2, name: 'Available careers' },
  ];

  const sims = Object.values(SIMS_DATA).filter(s =>
    filter !== 1
      ? true
      : s.career?.id !== 'STU' && s.career?.id !== 'FRE' && s.career?.id !== 'OWN' && s.career?.id !== 'SEL',
  );

  const getSortingFunction = forceAlpha => {
    if (forceAlpha || sortOrder === 0) {
      return (a, b) => (a.name > b.name ? 1 : -1);
    }

    if (sortOrder === 1) {
      return (a, b) => {
        const simsA = sims.filter(s => s.career?.id === a._id);
        const simsB = sims.filter(s => s.career?.id === b._id);

        return simsA.length >= simsB.length ? 1 : -1;
      };
    }

    if (sortOrder === 2) {
      return (a, b) => {
        const simsA = sims.filter(s => s.career?.id === a._id);
        const simsB = sims.filter(s => s.career?.id === b._id);

        return simsA.length <= simsB.length ? 1 : -1;
      };
    }

    if (sortOrder === 3) {
      return (a, b) => {
        return a.topWeeklyWages <= b.topWeeklyWages ? 1 : -1;
      };
    }

    if (sortOrder === 4) {
      return (a, b) => {
        const simsA = getAverageCareerLevel(a._id);
        const simsB = getAverageCareerLevel(b._id);
        return simsA <= simsB ? 1 : -1;
      };
    }
  };

  const jobs = Object.values(CAREERS_DATA).filter(s =>
    filter === 1
      ? s._id !== 'FRE' && s._id !== 'OWN' && s._id !== 'SEL' && s._id !== 'STU'
      : filter === 2
      ? sims.filter(sim => sim.career?.id === s._id).length < MAX_CAREER_SIMS
      : true,
  );

  const constructured = jobs
    .map(job => {
      const simsParsed = sims.filter(s => s.career && s.career.id === job._id).map(s => s._id);

      const sim = {};
      const fakeCareer = {
        id: job._id,
        level: 10,
      };

      const careerLevel = getSimCareer(fakeCareer);
      const branchesLevel = {};

      job.branches &&
        Object.keys(job.branches).forEach(b => {
          const fakeBranch = { ...fakeCareer, branch: b };
          const branchLevel = getSimCareer(fakeBranch);
          branchesLevel[b] = branchLevel;
        });

      const wages = {
        base: getWeeklyPayMoney(careerLevel, sim),
      };

      job.branches &&
        Object.keys(job.branches).forEach(b => {
          wages[b] = getWeeklyPayMoney(branchesLevel[b], sim);
        });

      return {
        _id: job._id,
        label: job.branches
          ? `${job.origin} | ${Object.keys(job.branches)
              .map(b => {
                return `${job.branches[b].name} (§ ${formatNumber(wages[b])})`;
              })
              .join(' & ')}.`
          : `${job.origin} | ${formatWeeklyMoney(wages.base)}`,
        topWeeklyWages: Math.max(...Object.values(wages)),
        name: `${job.name} (${simsParsed.length})`,
        extraStuff: {
          avg: getAverageCareerLevel(job._id),
          levels: getCareerLevels(job._id),
        },
        url: null,
        description: job.description,
        simIds: simsParsed,
        petIds: null,
      };
    })
    .sort(getSortingFunction(true))
    .sort(getSortingFunction());

  return (
    <main className="careers--wrapper">
      <div className="content careers--title">
        <h1>Careers and co-workers</h1>
      </div>

      <div className="families--sorting-box">
        {sortOrders.map(f => (
          <button
            key={f.id}
            className={`families--button ${sortOrder === f.id ? 'is-active' : ''}`}
            onClick={() => setSortOrder(f.id)}
          >
            {f.name}
          </button>
        ))}
      </div>

      <div className="families--sorting-box">
        {filters.map(f => (
          <button
            key={f.id}
            className={`families--button ${filter === f.id ? 'is-active' : ''}`}
            onClick={() => setFilter(f.id)}
          >
            {f.name}
          </button>
        ))}
      </div>

      <div className="content">
        {constructured.map(c => (
          <SimGroup key={c.name} {...c} showCareerBranch={true} />
        ))}
      </div>
    </main>
  );
}

export default Careers;

const getAverageCareerLevel = careerId => {
  const sims = Object.values(SIMS_DATA).filter(s => s.career?.id === careerId);
  const simsCount = sims.length;

  console.log(
    careerId,
    sims.map(s => s.career.level),
    sims.reduce((acc, nextSim) => acc + (nextSim.career?.level || 0), 0),
  );

  return Math.round((sims.reduce((acc, nextSim) => acc + (nextSim.career?.level || 0), 0) / simsCount) * 100) / 100;
};
const getCareerLevels = careerId => {
  const sims = Object.values(SIMS_DATA).filter(s => s.career?.id === careerId);
  const levels = new Array(11).fill(0);

  for (let index = 0; index < sims.length; index++) {
    const element = sims[index];
    const careerLevel = element.career?.level || 0;
    levels[careerLevel] = (levels[careerLevel] || 0) + 1;
  }

  return levels;
};
