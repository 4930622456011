import WORLDS_DATA from '../../data/worlds.json';
import LOTS_DATA from '../../data/lots.json';
import HOUSEHOLDS_DATA from '../../data/households.json';

export const lotHasHouseholds = (lotId, worldId, lotIndex) => {
  const lot = LOTS_DATA[lotId] || WORLDS_DATA[worldId].lots[lotIndex];
  const createdLotIndex = lotId?.replace(worldId, '') || lotIndex + 1;

  if (!isResidentalLot(lot.type)) {
    return true;
  }

  const household = Object.values(HOUSEHOLDS_DATA).filter(
    h => h?.home?.world === worldId && h?.home?.lot === createdLotIndex,
  );

  return household.length > 0;
};

export const checkHouseholdOnLot = (lot, worldId, lotIndex) => {
  const data = lot.lot ? LOTS_DATA[lot.lot] : {};
  const lotType = lot.type || data.type;
  const lotId = data._id || `${worldId}${lotIndex + 1}`;
  const lotOnly = lotId?.replace(worldId, '');

  if (!isResidentalLot(lotType)) {
    return true; // No need for household so it is A-OK
  }

  const household = Object.values(HOUSEHOLDS_DATA).filter(
    h => h?.home?.world === worldId && String(h?.home?.lot) === String(lotOnly),
  );

  return household.length === 1;
}

export const isResidentalLot = (lotType) => {
  return lotType === 'Residential' || lotType === 'Tiny House' || lotType === 'Apartments';
};
