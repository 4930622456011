import React from 'react';

import ListsSims from 'sections/lists/ListsSims';

import './AllSims.scss';

function AllSims(props) {
  return (
    <main className="all-sims--wrapper">
      <div className="content">
        <ListsSims
          history={props.history}
          path={props.location.pathname}
          queryParams={props.location.search.replace(/^\?/, '')}
        />
      </div>
    </main>
  );
}

export default AllSims;
