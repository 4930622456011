import React from 'react';
import { Link } from 'react-router-dom';

import './Worlds.scss';

import WORLDS_DATA from '../data/worlds.json';
import { checkHouseholdOnLot } from 'shared/utils/worlds';

function Worlds() {
  const worldsList = Object.values(WORLDS_DATA).sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <main className="worlds--wrapper">
      <div className="content worlds--title">
        <h1>By world</h1>
      </div>
      <div className="content worlds--content">
        {worldsList.map(w => {
          const planned = w.lots.length > 0 && w.lots.every(l => l.lot || l.type !== '???');
          const populated =
            w.lots.length > 0 &&
            w.lots.every((l, i) => checkHouseholdOnLot(l, w._id, i));
          const built = w.lots.length > 0 && w.lots.every(l => l.lot);

          return w.lots.length > 1 ? (
            <Link
              to={`/world/${w._id}`}
              key={w._id}
              className={`worlds--link ${w.name.split(' ')[0].length > 9 ? 'use-small' : ''}`}
              style={{
                opacity: planned ? (populated ? (built ? 1 : 0.6) : 0.3) : 0.1,
              }}
            >
              {w.name}
            </Link>
          ) : null;
        })}
      </div>
    </main>
  );
}

export default Worlds;
