import React from 'react';

import './SetupSim.scss';

import SIMS_DATA from '../../data/sims.json';
import CLUBS_DATA from '../../data/clubs.json';
import LOTS_DATA from '../../data/lots.json';
import RELATIONS_DATA from '../../data/relations.json';

function SetupSim(props) {
  const sim = props.sim;

  const clubs = Object.values(CLUBS_DATA);
  const clubLeader = clubs.filter(c => c.leader === sim._id);
  const clubLeaderIds = clubLeader.map(c => c._id);
  const clubMember = clubs.filter(c => c.members?.includes(sim._id) && !clubLeaderIds.includes(c._id));

  const mother = SIMS_DATA[sim.family?.mother];
  const father = SIMS_DATA[sim.family?.father];

  const allRelations = Object.values(RELATIONS_DATA).filter(r => r._id.startsWith(sim._id) || r._id.endsWith(sim._id));
  const married = allRelations.filter(r => r.family === 'Married');
  const engaged = allRelations.filter(r => r.family === 'Engaged');
  const dating = allRelations.filter(r => r.family === 'Dating');
  const divorced = allRelations.filter(r => r.family === 'Divorced');
  const ex = allRelations.filter(r => r.family === 'Ex-partners');
  const bffs = allRelations.filter(r => r.title === 'BFF');

  // Death!

  return (
    <div className="setup--sim">
      <div className="setup--sim-name">
        {sim.firstname} {sim.lastname}
      </div>

      {sim.family && (
        <div>
          {mother && (
            <div className="setup--sim-potental">
              <div>
                Mother: {mother?.firstname} {mother?.lastname}
              </div>
              <div>
                <pre>
                  fam {mother?.firstname} {mother?.lastname} {sim.firstname} {sim.lastname} parent
                </pre>
              </div>
            </div>
          )}
          {father && (
            <div className="setup--sim-potental">
              <div>
                Father: {father?.firstname} {father?.lastname}
              </div>
              <div>
                <pre>
                  fam {father?.firstname} {father?.lastname} {sim.firstname} {sim.lastname} parent
                </pre>
              </div>
            </div>
          )}
        </div>
      )}

      {married.map(r => {
        const sims = r._id.split('-');
        const simA = SIMS_DATA[sims[0]];
        const simB = SIMS_DATA[sims[1]];

        return (
          <div className="setup--sim-potental" key={r._id}>
            <div>
              Marriage: {simA?.firstname} {simA?.lastname} + {simB?.firstname} {simB?.lastname}
            </div>
            <div>
              <pre>
                fam {simA?.firstname} {simA?.lastname} {simB.firstname} {simB.lastname} spouse
              </pre>
            </div>
          </div>
        );
      })}

      {engaged.map(r => {
        const sims = r._id.split('-');
        const simA = SIMS_DATA[sims[0]];
        const simB = SIMS_DATA[sims[1]];

        return (
          <div className="setup--sim-potental" key={r._id}>
            <div>
              Engagement: {simA?.firstname} {simA?.lastname} + {simB?.firstname} {simB?.lastname}
            </div>
            <div>
              <pre>
                fam {simA?.firstname} {simA?.lastname} {simB.firstname} {simB.lastname} fiancee
              </pre>
            </div>
          </div>
        );
      })}

      {dating.map(r => {
        const sims = r._id.split('-');
        const simA = SIMS_DATA[sims[0]];
        const simB = SIMS_DATA[sims[1]];

        return (
          <div className="setup--sim-potental" key={r._id}>
            <div>
              Dating: {simA?.firstname} {simA?.lastname} + {simB?.firstname} {simB?.lastname}
            </div>
            <div>
              <pre>
                fam {simA?.firstname} {simA?.lastname} {simB.firstname} {simB.lastname} partner
              </pre>
            </div>
          </div>
        );
      })}

      {divorced.map(r => {
        const sims = r._id.split('-');
        const simA = SIMS_DATA[sims[0]];
        const simB = SIMS_DATA[sims[1]];

        return (
          <div className="setup--sim-potental" key={r._id}>
            <div>
              Divorce: {simA?.firstname} {simA?.lastname} + {simB?.firstname} {simB?.lastname}
            </div>
            <div>
              <pre>
                fam {simA?.firstname} {simA?.lastname} {simB.firstname} {simB.lastname} ex_spouse
              </pre>
            </div>
          </div>
        );
      })}

      {ex.map(r => {
        const sims = r._id.split('-');
        const simA = SIMS_DATA[sims[0]];
        const simB = SIMS_DATA[sims[1]];

        return (
          <div className="setup--sim-potental" key={r._id}>
            <div>
              Exes: {simA?.firstname} {simA?.lastname} + {simB?.firstname} {simB?.lastname}
            </div>
            <div>
              <pre>???</pre>
            </div>
          </div>
        );
      })}

      {bffs.map(r => {
        const sims = r._id.split('-');
        const simA = SIMS_DATA[sims[0]];
        const simB = SIMS_DATA[sims[1]];

        return (
          <div className="setup--sim-potental" key={r._id}>
            <div>
              BFFs: {simA?.firstname} {simA?.lastname} + {simB?.firstname} {simB?.lastname}
            </div>
            <div>
              <pre>
                rel {simA?.firstname} {simA?.lastname} {simB.firstname} {simB.lastname} friend best
              </pre>
            </div>
          </div>
        );
      })}

      {sim.lifeState?.startsWith('Ghost') && (
        <div className="setup--sim-potental">
          <div>Ghost</div>
          <div>
            <pre>
              {sim.lifeState} - order {sim.deathOrder}
            </pre>
          </div>
        </div>
      )}

      {sim.celebrity && (
        <div className="setup--sim-potental">
          <div>Celebrity level {sim.celebrity}</div>
          <div>
            <pre>UI Cheats</pre>
          </div>
        </div>
      )}

      {clubLeader.length > 0 &&
        clubLeader.map(c => {
          return (
            <div className="setup--sim-potental" key={c._id}>
              <div>
                <div>
                  <img src={`/images/clubs/${c._id}.png`} alt={c.name} width="30px" style={{ marginRight: '10px' }} />
                  {c.secret && <strong>!</strong>} {c.name}
                </div>

                <p>{c.description}</p>
                <div>
                  {c.requirements.map(r => r.value).join(' —— ')}
                  {' || '} {LOTS_DATA[c.meetingLot]?.name || c.meetingLot}
                </div>
                <div>{c.members.map(m => SIMS_DATA[m]?.firstname + ' ' + SIMS_DATA[m]?.lastname).join(', ')}</div>
                <div className="setup--sim-club">
                  <ul>
                    {c.positiveActivity?.map(s => (
                      <li key={s}>{s}</li>
                    ))}
                  </ul>
                  <ul>
                    {c.negativeActivity?.map(s => (
                      <li key={s}>{s}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div>
                <em>In game GUI</em>
              </div>
            </div>
          );
        })}

      {clubMember.length > 0 &&
        clubMember.map(c => {
          return (
            <div className="setup--sim-potental" key={c._id}>
              <div>
                Member of: {c.name}
                <img src={`/images/clubs/${c._id}.png`} alt={c.name} width="20px" style={{ marginLeft: '10px' }} />
              </div>
              <div>
                <em>In game GUI</em>
              </div>
            </div>
          );
        })}

      {/* <div>Owner</div>*/}
    </div>
  );
}

export default SetupSim;
