import React from 'react';
import { Link } from 'react-router-dom';

import './ProfileRelations.scss';

import SIMS_DATA from '../../data/sims.json';
import PETS_DATA from '../../data/pets.json';
import CLUBS_DATA from '../../data/clubs.json';

import { getRelationsTitle } from 'shared/utils/relations';

function ProfileRelations({ relationships, simId, family }) {
  const clubs = Object.values(CLUBS_DATA).filter(c => c.members.includes(simId));
  const clubIds = clubs.map(c => c.members).flat()
  
  const fakeIds = relationships.map(r => r._id.split('-').filter(s => s !== simId)[0])
  const newIdsFromHousehold = family.members.filter(id => fakeIds.indexOf(id) < 0);
  const newIdsFromClub = clubIds.filter(id => fakeIds.indexOf(id) < 0);

  const fakeHousehold = newIdsFromHousehold.filter(id => simId !== id).map(id => ({_id: `${simId}-${id}`}));
  const fakeClub = newIdsFromClub.filter(id => simId !== id).map(id => ({_id: `${simId}-${id}`}));
  const allRelationships = [...relationships, ...fakeHousehold, ...fakeClub].map(r => {
    const id = r._id.split('-').filter(s => s !== simId)[0];

    return {
      ...r,
      name: `${SIMS_DATA[id]?.firstname} ${SIMS_DATA[id]?.lastname}`
    }
  });

  if (!allRelationships || !allRelationships.length) {
    return null;
  }


  return (
    <div className="relationships--wrapper">
      {allRelationships
        .sort((a, b) => (a._id >= b._id ? 1 : -1)) // Fallback - charIds A-Z
        .sort((a, b) => (a.name > b.name ? 1 : -1)) // Firstname A-Z
        .map(r => {
          const id = r._id.split('-').filter(s => s !== simId)[0];

          const titles = getRelationsTitle(simId, id, r);
          const description = r.description ? (
            <span aria-label={r.description} title={r.description} role="img">
              🔮
            </span>
          ) : null;

          if (r.isPet) {
            return (
              <div key={id} className="relationships--single">
                <img
                  src={`/images/pets/${id}.jpg`}
                  alt=""
                  className="relationships--image"
                />
                <div className="relationships--text">
                  <div className="relationships--name">
                    {PETS_DATA[id]?.name}
                  </div>
                  <div className="relationships--status">
                    {titles} ({r.friendship})
                  </div>
                </div>
              </div>
            );
          }

          return (
            <Link
              key={id}
              to={`/all/${id}`}
              className="relationships--single relationships--link"
            >
              <img
                src={`/images/sims/${id}.jpg`}
                alt=""
                className="relationships--image"
              />
              <div className="relationships--text">
                <div className="relationships--name">
                  {SIMS_DATA[id]?.firstname} {SIMS_DATA[id]?.lastname}
                </div>
                <div className="relationships--status">
                  {titles} {description}
                </div>
              </div>
            </Link>
          );
        })}
    </div>
  );
}

export default ProfileRelations;
