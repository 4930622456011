import React from 'react';

import './FavSim.scss';

import SIMS_DATA from '../../data/sims.json';
import ProfileTop from 'sections/profile/ProfileTop';

function FavSim({ simId }) {
  const sim = SIMS_DATA[simId];

  if (!simId || !sim) {
    return null;
  }

  return (
    <div>
      <ProfileTop sim={sim} shortVersion={true} />
    </div>
  );
}

export default FavSim;
