import React from 'react';

import './Home.scss';

function Home() {
  return (
    <main className="home--wrapper">
      <div className="content home--title">
        <h1>Mods</h1>
        <p>TODO: Update list and add more mods that I like.</p>
      </div>

      <div className="content home--content">
        <div className="home--item">
          <div className="home--item-title">Situational outfits</div>
          <div className="home--item-description">
            <p>
              I'm using a mod by <a href="https://modthesims.info/m/10195028">plzaysike</a> called "
              <a href="https://modthesims.info/d/645748">Ugly Townie Outfit Eliminator</a>
              ".
            </p>
            <p>
              It does remove the outfits for Knight and Bear Nights from Get Together as well as festival outfits
              (excluding geekcon) too, So be aware of that.
            </p>
          </div>
        </div>

        <div className="home--item">
          <div className="home--item-title">Always night in Forgotten Hollow</div>
          <div className="home--item-description">
            <p>
              I'm using a mod by <a href="https://www.patreon.com/TwistedMexi/posts">TwistedMexi</a> called "
              <a href="https://www.patreon.com/posts/underworlds-and-38033191">Underworld</a>
              ". It makes it so that it is always night in the world, and I use it for just Forgotten Hollow.
            </p>
            <p>
              In combination with this I also use a mod by <a href="https://littlemssam.tumblr.com">LittleMsSam</a>{' '}
              called "
              <a href="https://littlemssam.tumblr.com/post/175411240598/no-burning-sun-in-forgotten-hollow-forgotton">
                No Burning Sun
              </a>
              ". This is because I got tired of Vampires getting killed by the sun in "their" world.
            </p>
          </div>
        </div>

        <div className="home--item">
          <div className="home--item-title">Acting Pay By Fame</div>
          <div className="home--item-description">
            <p>
              Adds a more realsitic payment for the acting career, being a celebrity and an actor should be a sure way
              to get wealthy. So the actor career now multiplies it's payout with the fame-level the sim is.
            </p>
            <p>
              Worth noting is these multipliers works the same way the fame perk "Double payout" works, as it doesn't
              show in the audition list nor does it affect the median payout from gigs. It is added as a multiplier when
              gaining the money.
            </p>
            <p>
              <small>This is what the actors weekly income in this data is based on.</small>
            </p>
          </div>
        </div>

        <div className="home--item">
          <div className="home--item-title">Club Requirements</div>
          <div className="home--item-description">
            <ul>
              <li>
                Increasing the limits for being considered "wealthy" to §150,000
                <ul>
                  <li>Wealthy: More than §150,000</li>
                  <li>Middle class: §25,000 - §150,000</li>
                  <li>Poor: Less than §25,000</li>
                </ul>
              </li>
              <li>Adds EP09 (Eco Lifestyle) traits</li>
              <li>Adds gender "traits"</li>
              <li>Adds all occults "traits"</li>
            </ul>
            <p>
              The main purpose is to increase the "Wealthy" limit, §20,000 is way to low. Especially when it seems to be
              household
              <em> value</em> and not household <em>funds</em>. Most houses are valued at above §20,000.
            </p>
            <p>
              Not sure why EP09 traits isn't in the list by default, so that might be fixed by EA in a future patch.
            </p>
            <p>
              Adding gender to the traits list is the easiest, even if it would be neat to have them in their own
              category (like the marriage flag), and also split Occult state into a separate category. But that is for
              another time!
            </p>
            <p>
              <strong>All clubs and requirements works without this kind of mod,</strong> since the traits are ingame
              traits. You just can't change or add them without a mod.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
