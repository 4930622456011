import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import './SimDetails.scss';

import SIMS_DATA from '../data/sims.json';
import WORLDS_DATA from '../data/worlds.json';
import HOUSEHOLDS_DATA from '../data/households.json';
import RELATIONS_DATA from '../data/relations.json';
import LOTS_DATA from '../data/lots.json';
import SKILLS_DATA from '../data/skills.json';

import ProfileTop from 'sections/profile/ProfileTop';
import ProfileRelations from 'sections/profile/ProfileRelations';
import ProgressSkills from 'sections/profile/ProgressSkills';
import RelatedSims from 'sections/profile/RelatedSims';

function SimDetails() {
  let { id } = useParams();

  const sim = SIMS_DATA[id] || {};
  const family = HOUSEHOLDS_DATA[sim.household] || {};

  if (Object.keys(sim).length === 0) {
    return (
      <div className="content is-empty u-empty">
        No sim with ID "<strong>{id}</strong>"
      </div>
    );
  }

  const world = family ? WORLDS_DATA[family.home?.world] : null;
  const lotId = `${family.home?.world}${family.home?.lot}${family.home?.flat || ''}`;
  const lot = world ? (LOTS_DATA[lotId] ? LOTS_DATA[lotId] : world.lots[family.home.lot - 1]) : {};

  const relationshipsIds = Object.keys(RELATIONS_DATA).filter(i => {
    const chars = i.split('-');
    return chars[0] === id || chars[1] === id;
  });
  const relationships = relationshipsIds.map(r => RELATIONS_DATA[r]);

  if (!sim) {
    return (
      <main className="sim--wrapper">
        <div className="content">
          <h1>No sim with ID {id}</h1>
        </div>
      </main>
    );
  }

  return (
    <main className="sim--wrapper">
      <ProfileTop sim={sim} />

      {sim.lifestyles?.length && (
        <div className="content">
          <div className="sim--fav">
            <div className="sim--fav-item">
              <div className="sim--fav-label">Lifestyles</div>
              <div className="sim--fav-value">{sim.lifestyles?.length ? sim.lifestyles.join(', ') : '-'}</div>
            </div>
          </div>
        </div>
      )}

      <div className="content">
        <div className="sim--introduction">{sim.description}</div>
      </div>

      <div className="content">
        {world ? (
          <>
            <Link to={`/world/${world._id}`} className="sim--world">
              <img src="/images/icons/world.png" alt="Home world" className="sim--world-icon" />
              <div>
                <div className="sim--world-name">{WORLDS_DATA[family.home?.world]?.name}</div>
                <div className="sim--world-lot">{family.home?.townie ? 'Townie' : lot?.name}</div>
              </div>
            </Link>
            <div className="sim--world-description">{lot?.description}</div>
          </>
        ) : (
          <div className="content is-empty u-empty">
            No world with ID "<strong>{family.home?.world}</strong>"
          </div>
        )}
      </div>

      {sim.skills && (
        <div className="content sim--skills">
          {sim.skills
            .sort((a, b) => (SKILLS_DATA[a.skill]?.name > SKILLS_DATA[b.skill]?.name ? 1 : -1))
            .map(s => (
              <div key={s.skill} className="sim--skill">
                <img src={`/images/icons/skills/${s.skill}.png`} alt={s.skill} className="sim--skill-icon" />
                <div className="sim--skill-text">
                  <div>{SKILLS_DATA[s.skill]?.name}</div>
                  <ProgressSkills s={s.skill} level={s.level} />
                </div>
              </div>
            ))}
        </div>
      )}

      <ProfileRelations relationships={relationships} simId={id} family={family} />

      <RelatedSims sim={sim} relationships={relationships} />
    </main>
  );
}

export default SimDetails;
