import React from 'react';
import { Link } from 'react-router-dom';

import './RelatedSims.scss';

import SIMS_DATA from '../../data/sims.json';
import CLUBS_DATA from '../../data/clubs.json';
import HOUSEHOLDS_DATA from '../../data/households.json';
import LOTS_DATA from '../../data/lots.json';
import WORLDS_DATA from '../../data/worlds.json';
import CAREERS_DATA from '../../data/careers.json';
import PETS_DATA from '../../data/pets.json';

function RelatedSims({ sim, relationships }) {
  const { household } = sim;
  const fullHousehold = HOUSEHOLDS_DATA[household];
  const fullWorld = WORLDS_DATA[fullHousehold?.home.world];
  const lotId = fullHousehold?.home.world + fullHousehold?.home.lot;
  const fullLot = LOTS_DATA[lotId];

  const clubs = Object.values(CLUBS_DATA).filter(c => c.members.includes(sim._id));

  const filterSpecificSim = id => r => r._id.split('-').filter(i => i !== sim._id)[0] === id;

  const simOwner = sim.career?.name?.split(', ')[1] || sim.career?.name;
  const allCoworkers = Object.values(SIMS_DATA)
    .sort((a, b) => (a.firstname >= b.firstname ? 1 : -1))
    .filter(s => s.career?.id === sim.career?.id)
    .filter(s => s._id !== sim._id) // Remove current sim
    .filter(s =>
      sim.career?.id === 'STU'
        ? sim.career.branch !== 'UF' && sim.career.branch !== 'UB'
          ? s.ageCode === sim.ageCode
          : s.career.branch === 'UF' || s.career.branch === 'UB'
        : true,
    ); // Only keep students of same age
  const samePart = allCoworkers.filter(s => s.career?.name === simOwner || s.career?.name?.split(', ')[1] === simOwner);

  return (
    <>
      {fullHousehold ? (
        <>
          <div className="content">
            <h2 className="related--section">
              Household{' '}
              <Link to={`/family/${fullHousehold._id}/`}>
                <em>{fullHousehold.name}</em>
              </Link>
            </h2>
            <div className="household--wrapper">
              <div className="household--home">
                {fullLot?.name} in {fullWorld?.name}
              </div>
              <p>{fullHousehold.description}</p>
            </div>
          </div>

          <div className="relationships--wrapper">
            {fullHousehold.members
              .sort((a, b) => (a >= b ? 1 : -1))
              .filter(s => s !== sim._id) // Remove current sim
              .map(s => (
                <SimRender key={s} sim={SIMS_DATA[s]} rel={relationships.filter(filterSpecificSim(s))[0]} />
              ))}
            {fullHousehold.pets
              ?.sort((a, b) => (a >= b ? 1 : -1))
              .filter(s => s !== sim._id) // Remove current sim
              .map(id => {
                
                return <div key={id} className="relationships--single">
                  <img
                    src={`/images/pets/${id}.jpg`}
                    alt=""
                    className="relationships--image"
                  />
                  <div className="relationships--text">
                    <div className="relationships--name">
                      {PETS_DATA[id]?.name}
                    </div>
                  </div>
                </div>
              })}
          </div>
        </>
      ) : (
        <div className="content is-empty u-empty">
          No household with ID "<strong>{household}</strong>"
        </div>
      )}

      {clubs.length > 0 && (
        <div className="content">
          <h2 className="related--section">Club members</h2>
        </div>
      )}
      {clubs &&
        clubs.map(c => (
          <div key={c._id}>
            <div className="content">
              <h3 className="related--group">{c.name}</h3>
              <div className="household--wrapper">
                <p>{c.description}</p>
              </div>
            </div>

            <div className="relationships--wrapper">
              {c.members
                .sort((a, b) => (a >= b ? 1 : -1))
                .filter(s => s !== sim._id) // Remove current sim
                .map(s => (
                  <SimRender
                    key={s}
                    sim={SIMS_DATA[s]}
                    clubLeader={c.leader === s}
                    rel={relationships.filter(filterSpecificSim(s))[0]}
                  />
                ))}
            </div>
          </div>
        ))}

      {sim.career && (
        <div className="content">
          <h2 className="related--section">Co-workers</h2>
          <h3 className="related--group">{CAREERS_DATA[sim.career.id].name}</h3>
          <div className="household--wrapper">
            <p>{CAREERS_DATA[sim.career.id].description}</p>
          </div>
        </div>
      )}
      {simOwner && samePart.length > 0 && (
        <div className="content">
          <h4 className="related--group">{simOwner}</h4>
        </div>
      )}
      {simOwner && (sim.career.id === 'OWN' || sim.career.id === 'SEL') && (
        <div className="relationships--wrapper">
          {samePart.map(s => (
            <SimRender
              key={s._id}
              sim={s}
              rel={relationships.filter(filterSpecificSim(s._id))[0]}
              showCareerBranch={true}
            />
          ))}
        </div>
      )}
      {simOwner && (
        <div className="content">
          <h4 className="related--group">All</h4>
        </div>
      )}
      {sim.career && (
        <div className="relationships--wrapper">
          {allCoworkers.map(s => (
            <SimRender
              key={s._id}
              sim={s}
              rel={relationships.filter(filterSpecificSim(s._id))[0]}
              showCareerBranch={true}
            />
          ))}
        </div>
      )}
    </>
  );
}

const SimRender = ({ sim, rel, clubLeader, showCareerBranch }) => {
  if (!sim) {
    return null;
  }

  return (
    <Link to={`/all/${sim._id}`} className="relationships--single relationships--link">
      <div className="relationships--image-wrapper">
        <img src={`/images/sims/${sim._id}.jpg`} alt="" className="relationships--image" />
        {clubLeader && (
          <span role="img" aria-label="Club leader" className="relationships--club">
            💫
          </span>
        )}
        {showCareerBranch && HasBranchesCareersCheck(sim.career?.id, sim.career?.branch) && sim.career?.branch && (
          <img
            src={`/images/icons/careers/${sim.career?.id}_${sim.career?.branch}i.png`}
            alt=""
            className="relationships--branch"
          />
        )}
      </div>
      <div className="relationships--text">
        <div className="relationships--name">
          {sim?.firstname} {sim?.lastname}
        </div>
      </div>
    </Link>
  );
};

const HasBranchesCareersCheck = (id, branch) => {
  if (!id) {
    return false;
  }

  if (id === 'STU' && branch !== 'UB' && branch !== 'UF') {
    return false;
  }

  if (id === 'OWN') {
    return false;
  }

  return true;
};

export default RelatedSims;
