import React from 'react';
import { Link } from 'react-router-dom';

import './Home.scss';

function Home() {
  return (
    <main className="home--wrapper">
      <div className="content home--title">
        <h1>
          <span>Monster</span>Save
        </h1>
      </div>

      <div className="content home--content">
        <div className="home--item">
          <div className="home--item-title">Original plan</div>
          <div className="home--item-description">
            <p>
              It started with me wanting co-workers in <em>any</em> career that
              I played, and then it grew into a monster.
            </p>
          </div>
        </div>

        <div className="home--item">
          <div className="home--item-title">Changes made</div>
          <div className="home--item-description">
            <ul>
              <li>Sims have skills that match their current career level.</li>
              <li>
                Updated homes to match their income, personalities, world, and
                any other adjustments.
              </li>
              <li>Sims have checked seasonal outfits.</li>
              <li>Club leaders knows everyone in the club.</li>
              <li>
                Households updated to spread out celebrities, pets, univeristy
                degrees, and occult sims.
              </li>
              <li>
                Parent have parenting skill depending on the age and amount of
                children.{' '}
                <small>
                  Family-Oriented trait and family aspirations increase this
                  skill level.
                </small>
              </li>
              <li>
                University graduates have research and debate skill and skills
                related to their degree depending on if they graduated with
                honors, and if the program is a distinguished one or not.
              </li>
              <li>Vampires know vampire lore.</li>
              <li>Each lot has at least one lot-trait.</li>
              <li>Dog owners have pet training, dog lovers have more.</li>
              <li>
                Sims have a sexuality that is reflected in their ingame
                gender-preference.
              </li>
            </ul>
            <p>
              This means some Maxis sims now have pets, some have updated
              careers, and some have moved around a bit. All lots ar updated to
              mix more packs, and each household has a custom renovation to
              their house to fit their family.
            </p>
          </div>
        </div>

        <div className="home--item">
          <div className="home--item-title">CC and Mods</div>
          <div className="home--item-description">
            <p>
              It is created with{' '}
              <a href="https://deaderpool-mccc.com/#/">MC Command Center</a>,{' '}
              <a href="https://www.patreon.com/posts/ui-cheats-v1-16-26240068">
                UI Cheats
              </a>
              , and{' '}
              <a href="https://sims4famcheats.tumblr.com/">
                Family Management Cheats
              </a>{' '}
              to setup relationships, careers, skills, and university degrees.
            </p>
            <p>There is no CC in the game.</p>
            <p>
              There is no mods that are <em>required</em>. With that said, there
              are{' '}
              <Link to="/mods">
                a few gameplay mods that I recommend listed here
              </Link>
              .
            </p>
          </div>
        </div>

        <div className="home--item">
          <div className="home--item-title">Sim inspirations</div>
          <div className="home--item-description">
            <p>
              All the base sims are still there, a few sims that appears in
              various trailers and are downloadable from Maxis gallery are also
              included. Some families have extensions from previous sims games{' '}
              <small>(eg. Bella Goth's brother Michael Bachelor)</small>, while
              others have custom made additions based on various stories about
              the sims{' '}
              <small>
                (eg. Nancy Landgraabs half-sister Estella Landgraab)
              </small>
              .
            </p>
            <p>
              There is also a bunch of custom ones. Many come from my own mind,
              while others are inspired by other's savefiles{' '}
              <small>(eg. ???)</small> or ingame things{' '}
              <small>
                (eg. Marco Steele from the billboards in San Myshuno)
              </small>
              . A bunch of sims from previous games can be seen as townies
              unless they have a direct relation to anyone already in the game{' '}
              <small>
                (eg. Nick and Vita Alto added as Holly Alto's parents)
              </small>
              .
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
